






































































import Vue from "vue";
import Stepper from "@/components/modules/Stepper.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import { IBookableService } from "@/store/types/Service";
import { featureFlags } from "@/store/feature-flag.store";

export default Vue.extend({
  components: {
    Stepper,
  },
  data() {
    return {
      checkedServices: [] as Array<IBookableService>,
      sum: 0,
      sumPackagingDisposal: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getService",
      "getOrder",
      "getSelectedAppointment",
    ]),
    ...mapState(["bookableServices"]),
    getSum(): number {
      return this.changeSum();
    },
  },
  methods: {
    determineStep() {
      return featureFlags.BOOKING_RMD_HIDE_DELIVERY_RESTRICTION ? 2 : 3
    },
    appointmentSelectionLocalized() {
      this.$navigateTo("appointmentSelection");
    },
    appointmentConfirmation() {
      for (const service of this.checkedServices) {
        service.sumPrice = this.getOrder.deliveryArticleCount * service.price;
      }
      this.SET_BOOKEDSERVICES(this.checkedServices);
      this.$navigateTo("appointmentConfirmation");
    },
    changeSum() {
      this.sum = 0;
      for (const service of this.checkedServices) {
        const priceService = this.getOrder.deliveryArticleCount * service.price;
        this.sum += priceService;
      }
      return this.sum;
    },
    ...mapMutations(["SET_BOOKEDSERVICES"]),
  },
});
