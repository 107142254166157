




















































import Vue from "vue";
import ImageTeaser from "@/components/modules/ImageTeaser.vue";
import EnvironmentService from '@/services/EnvironmentService';
import { mapState} from "vuex";

export default Vue.extend({
  components: {
    ImageTeaser,
  },
  data(){
        return {clientsExcludedFromContactForm: EnvironmentService.applicationConfiguration.clientsExcludedFromContactForm.split(','),
         }
  },
   computed: {
    ...mapState([
      "service"
    ]),
    isCurrentLocaleGerman(): boolean {
      return this.$i18n.locale === 'de';
    },
  },
  methods:{
contactFormIsVisible: function() {return EnvironmentService.applicationConfiguration.clientsExcludedFromContactForm.split(',').includes(this.service.order.clientKey)}
  }
});

