import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import i18n from "@/i18n";
import {reCaptchaService} from "@/services/ReCaptchaService";

abstract class AxiosService {
    axiosInstance: AxiosInstance;

    constructor(baseUrl: string) {
        // for local development
        this.axiosInstance = axios.create({
            baseURL: baseUrl
        });
    }

    updateBaseUrl(baseUrl: string) {
        // for stages (after environment.json is fetched)
        this.axiosInstance.defaults.baseURL = baseUrl;
    }

    private async createConfig(action: string) {
        let currentLocale = i18n.locale;
        if (currentLocale === 'cz') {
            currentLocale = 'cs';
        }

        const headers = {
            'RH-Locale': currentLocale,
        } as any;

        if (action) {
            headers["RMD-APP-TOKEN"] = await reCaptchaService.execute(action);
        }

        return {
            headers: headers,
        } as AxiosRequestConfig;
    }

    async get<T = any>(endpoint: string, parameters: object, action: string) {
        const config = await this.createConfig(action)
        return await this.axiosInstance.get<T>(endpoint, {
            ...config,
            params: parameters,
        });
    }

    async post<T = any>(endpoint: string, payload: object, action: string) {
        const config = await this.createConfig(action);
        return await this.axiosInstance.post<T>(endpoint, payload, config);
    }

    async getBlob(endpoint: string, action: string) {
        const config = await this.createConfig(action);
        return await this.axiosInstance.get(endpoint, {
            ...config,
            responseType: 'blob',
        });
    }
}

class ApiService extends AxiosService {
    constructor() {
        super(process.env.VUE_APP_BOOKING_SERVICE_URL);
    }
}

class LegacyApiService extends AxiosService {
    constructor() {
        super(process.env.VUE_APP_API_URL);
    }
}

const apiService = new ApiService();
const legacyApiService = new LegacyApiService();

export {apiService, legacyApiService};
