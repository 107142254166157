




































































































































import Vue from "vue";
import HighlightStrip from "@/components/modules/HighlightStrip.vue";
import StickyBanner from "@/components/modules/StickyBanner.vue";
import TrackingMap from "@/components/modules/TrackingMap.vue";
import TrackingStatus from "@/components/modules/TrackingStatus.vue";
import TrackingMapPlaceholder from "@/components/modules/TrackingMapPlaceholder.vue";
import { mapState, mapActions } from "vuex";
import PlanedDeliveryHelper from "@/helpers/PlanedDeliveryHelper";
import "@/extensions/DateExtensions.ts";
import "@/extensions/StringExtensions.ts";
import ViewportHelper from "@/helpers/ViewportHelper";
import { getRouteName } from "@/routeConfig";
import { featureFlags } from "@/store/feature-flag.store";
import { trackingService } from "@/services/TrackingService";
import { TrackingMapVisibilityService } from "@/services/TrackingMapVisibilityService";

const viewportHelper = new ViewportHelper();

export default Vue.extend({
  components: {
    HighlightStrip,
    StickyBanner,
    TrackingStatus,
    TrackingMap,
    TrackingMapPlaceholder
  },
  data() {
    return {
      planedDelivery: new PlanedDeliveryHelper(),
      pageComplete: false,
      mapsAvailable: true,
      mapsLive: false,
      isMobile: true,
      showMap: false,
      eta: "" as string | null,
      NA: false,
      secondLastState: {
        orderState: "",
        creationTime: null,
        id: null
      },
      stages: [
        {
          name: "registration",
          title: this.$i18n.t("order.orderState.created"),
          date: "",
          status: ""
        },
        {
          name: "arrival",
          title: this.$i18n.t("order.orderState.goodsReceived"),
          date: "",
          status: ""
        },
        {
          name: "transportation",
          title: this.$i18n.t("order.orderState.inDelivery"),
          date: "",
          status: ""
        },
        {
          name: "delivery",
          title: this.$i18n.t("order.orderState.delivered"),
          date: "",
          status: ""
        }
      ],
      hideBanner: false,
      trackingMapVisibilityService: new TrackingMapVisibilityService(this.$store)
    };
  },
  async mounted() {
    this.initStickyBottom();
    
    if (featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_TRACKING) {
      trackingService.GetEstimatedTimeOfArrival(this.order.internalOrderId).then(response => {
        this.eta = response.estimatedArrivalTime ? response.estimatedArrivalTime.toString() : null;
      });
    }
    else {
      this.eta = this.service.actualInformationTo.estimatedArrivalTime
    }

    
    if (featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_TRACKING) {      
      this.showMap = this.trackingMapVisibilityService.showTrackingMap()
      if (this.showMap === true){
        await this.getDeliveryTruckMapData();
      }
    } else {
      this.showMap = this.service.order.orderState === "UnterwegsZumKunden";
    }
    
    if (featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_STATUS_TRACKING) {
      trackingService.GetTrackingStatus(this.order.internalOrderId).then(response => {

        response.data.forEach((stage, index) => {

          if (stage.date != null)
            this.stages[index].date = Vue.filter("formatDateWithTime")(stage.date)

          if (stage.title != "")
            this.stages[index].title = this.$i18n.t(`order.orderState.${stage.title}`);

          this.stages[index].status = stage.status == "Undefined" ? "" : stage.status.toLowerCase();

        });
      }
      );
    }
    else {
      const stateList = this.service.stateHistoryList;
      this.secondLastState = stateList[stateList.length - 2];
      const actualState = {
        orderState: this.service.order.orderState,
        creationTime: this.service.order.createDate
      };
      if (stateList.length == 0) {
        stateList.push(actualState);
      }

      for (const state of stateList) {
        switch (state.orderState) {
          case "AuftragErfasstMandant":
          case "AuftragErfasst":
          case "AuftragsdatenImLSC":
          case "AuftragErfasstSchnittstelle":
            this.stages[0].date = Vue.filter("formatDateWithTime")(
              state.creationTime
            );
            this.stages[0].status = "success";
            break;
          case "UnterwegsZumKunden":
            if (this.NA == true) {
              this.stages[3].status = "";
              this.stages[3].title = this.$i18n
                .t("order.orderState.delivered")
                .toString();
              this.stages[3].date = "";
            }
            this.stages[1].date = Vue.filter("formatDateWithTime")(
              state.creationTime
            );
            this.stages[2].date = Vue.filter("formatDateWithTime")(
              state.creationTime
            );
            this.stages[0].status = "success";
            this.stages[1].status = "success";
            this.stages[2].status = "success";
            break;
          case "Abweichung":
          case "ArtikelabweichungImLSC":
          case "VerladungUnvollstaendig":
          case "HauptlaufUnvollstaendig":
          case "AuftragTeilweiseImHub":
            this.stages[0].status = "success";
            this.stages[1].status = "warning";
            this.stages[1].date = Vue.filter("formatDateWithTime")(
              state.creationTime
            );
            this.stages[1].title = this.$i18n
              .t("order.orderState.problemInDepot")
              .toString();
            break;
          case "AbgeschlossenNachZL":
          case "AbsortiertImStandort":
          case "AbsortiertImZL":
          case "ArtikelImLSCEingetroffen":
          case "ArtikelVollstaendigImLSC":
          case "AuftragVollstaendig":
          case "UnterwegsZumLSC":
          case "VerladungImLSCBegonnen":
          case "VerladungImZL":
          case "NichtBearbeiten":
          case "RetoureAusgegangen":
          case "RetoureBereitgestellt":
          case "RetoureTeilweiseEingegangen":
          case "Schieben":
          case "TagesabschlussGeschoben":
          case "ZurAuslieferungBereitgestellt":
            this.stages[0].status = "success";
            this.stages[1].status = "success";
            this.stages[1].title = this.$i18n
              .t("order.orderState.goodsReceived")
              .toString();
            this.stages[1].date = Vue.filter("formatDateWithTime")(
              state.creationTime
            );
            break;
          case "Abgerechnet":
          case "Erledigt":
          case "TagesabschlussErfolgt":
            this.stages[0].status = "success";
            this.stages[1].status = "success";
            this.stages[2].status = "success";
            this.stages[3].date = Vue.filter("formatDateWithTime")(
              state.creationTime
            );
            this.stages[3].status = "success";
            this.stages[3].title = this.$i18n
              .t("order.orderState.delivered")
              .toString();
            break;
          case "NA":
            this.stages[0].status = "success";
            this.stages[1].status = "success";
            this.stages[2].status = "success";
            this.stages[3].date = Vue.filter("formatDateWithTime")(
              state.creationTime
            );
            this.stages[3].status = "warning";
            this.stages[3].title = this.$i18n
              .t("order.orderState.deliveryStopped")
              .toString();
            this.NA = true;
            break;
          case "Storno":
          case "StornoZL":
            switch (this.secondLastState.orderState) {
              case "AuftragErfasstMandant":
              case "AuftragErfasst":
              case "AuftragsdatenImLSC":
              case "AuftragErfasstSchnittstelle":
              case "StornoZL":
                this.stages[0].status = "warning";
                this.stages[0].title = "Storno";
                break;
              case "ArtikelVollstaendigImLSC":
              case "UnterwegsZumLSC":
                this.stages[1].date = Vue.filter("formatDateWithTime")(
                  state.creationTime
                );
                this.stages[1].status = "warning";
                this.stages[1].title = "Storno";
                break;
              case "UnterwegsZumKunden":
                this.stages[2].date = Vue.filter("formatDateWithTime")(
                  state.creationTime
                );
                this.stages[2].status = "warning";
                this.stages[2].title = "Storno";
                break;
              case "Abgerechnet":
              case "Erledigt":
              case "TagesabschlussErfolgt":
              case "NA":
              case "Storno":
                this.stages[3].date = Vue.filter("formatDateWithTime")(
                  state.creationTime
                );
                this.stages[3].status = "warning";
                this.stages[3].title = "Storno";
                break;
            }
            break;
        }
      }
    }

    window.addEventListener("resize", this.resizing);
    window.addEventListener("scroll", this.scrolling);

    this.checkIfMobile();
    this.checkIfPositionAvailable();

    if (
      this.service.order.address.coordinates.latitude === "0" ||
      this.service.order.address.coordinates.latitude === null
    ) {
      this.getGeoCoordinatesByAddress()
        .then(() => {
          this.service.order.address.coordinates = this.clientLatLng;
        })
        .catch(() => {
          this.mapsAvailable = false;
        });
    }
  },
  created() {
    this.$nextTick(() => {
      this.scrolling();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizing);
    window.removeEventListener("scroll", this.scrolling);
  },
  computed: {
    ...mapState([
      "isLoggedIn",
      "service",
      "order",
      "order",
      "clientLatLng",
      "notificationDescription",
      "stickyBottom"
    ])
  },
  methods: {
    ...mapActions(["getDeliveryTruckMapData"]),
    getLinkToDeliveryRestrictions: function() {
      return { name: getRouteName("deliveryRestriction") };
    },
    checkIfPositionAvailable() {
      if (
        this.service.actualInformationTo.gpsLongitude === "" ||
        this.service.actualInformationTo.gpsLongitude === null
      ) {
        this.mapsAvailable = false;
      }
    },
    remainingOrders() {
      return (
        this.service.order.orderState == "UnterwegsZumKunden" &&
        this.service.actualInformationTo.remainingOrders != null &&
        this.service.actualInformationTo.remainingOrders != ""
      );
    },
    notifiedAppointmentExists() {
      return (
        this.service.order.notifiedAppointment != null &&
        this.service.order.notifiedAppointment != ""
      );
    },
    estimatedArrivalTimeExists() {
      return (this.eta!=null && this.eta !="0001-01-01T00:00:00" && this.eta != "")
    },
    getETA()
    {
      return this.eta ? new Date(this.eta).toLocaleString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: undefined
      }) : null;
    },
    checkIfMobile() {
      this.isMobile = !window.matchMedia("(min-width: 1080px)").matches;
    },
    activateAndfocusMap() {
      this.mapsLive = true;
      if (this.isMobile) return;

      this.$nextTick(() => {
        const map = document.getElementsByClassName("maps-desktop")[0];
        map.scrollIntoView({ behavior: "smooth", block: "center" });
      });
    },
    resizing() {
      this.checkIfMobile();
      this.scrolling();
    },
    scrolling() {
      if (this.mapsLive || !this.mapsAvailable || !this.showMap) return;

      const mapPlaceholderVueComponent = this.$refs.mapPlaceholder as Vue;
      const mapPlaceholder = mapPlaceholderVueComponent.$el;
      const trackingButton = mapPlaceholder.getElementsByTagName("button")[0];

      this.hideBanner = viewportHelper.isInViewport(trackingButton, 6);

      if (this.hideBanner) return;

      this.updateStickyBottom();
    },
    ...mapActions([
      "getGeoCoordinatesByAddress",
      "initStickyBottom",
      "updateStickyBottom"
    ])
  }
});
