






















import Vue from "vue";
import HeroText from "@/components/modules/HeroText.vue";
import { mapState } from "vuex";

export default Vue.extend({
  components: {
    HeroText,
  },
  data() {
    return {
      options: [
        {
          title: this.$t("contactForm.additionalService"),
          id: "additionalService",
        },
        {
          title: this.$t("contactForm.appointment"),
          id: "appointment",
        },
        {
          title: this.$t("contactForm.change"),
          id: "change",
        },
        {
          title: this.$t("contactForm.orderStatus"),
          id: "orderStatus",
        },
        {
          title: this.$t("contactForm.changeOfAddress"),
          id: "changeOfAddress",
        },
        {
          title: this.$t("contactForm.changeOfContactDetails"),
          id: "changeOfContactDetails",
        },
        {
          title: this.$t("contactForm.deliveryRestrictions"),
          id: "deliveryRestrictions",
        },
        {
          title: this.$t("contactForm.documentRequest"),
          id: "documentRequest",
        },
        {
          title: this.$t("contactForm.orderCancellation"),
          id: "orderCancellation",
        },
        {
          title: this.$t("contactForm.complaint"),
          id: "complaint",
        },
        {
          title: this.$t("contactForm.orderQuestions"),
          id: "orderQuestions",
        },
        {
          title: this.$t("contactForm.privacyQuestions"),
          id: "privacyQuestions",
        },
        {
          title: this.$t("contactForm.other"),
          id: "other",
        }
      ],
      subject: "",
      name: "",
      orderNumber: "",
      postcode: "",
      telephoneNumber: "",
      email: "",
      message: "",
    };
  },

  computed: {
    ...mapState(["isLoggedIn"]),
  },
  methods: {
    getStartpageLocalized() {
      this.$navigateTo("startpage");
    },
    getOrderLocalized() {
      this.$navigateTo("order");
    },
  },
});
