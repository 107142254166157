



















import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      activeTabId: "",
    };
  },
  props: {
    options: {
      type: Array as () => IOption[],
      required: true,
      default: [],
    },
    innerWidth: {
      type: Number,
      default: 1080,
    },
    default: {
      type: String,
    },
  },
  mounted() {
    if (this.options.length) this.activeTabId = this.options[0].id;
  },
  computed: {
    widthStyle(): { maxWidth: string } {
      return {
        maxWidth: this.innerWidth + "px",
      };
    },
  },
  methods: {
    changeTab(tab: IOption) {
      this.activeTabId = tab.id;
      this.$emit("change", tab);
    },
  },
});
