import axios from "axios";
import EnvironmentService from "@/services/EnvironmentService";
import {FeatureFlagResponse} from "@/services/types/FeatureFlagResponse";
import { featureFlags } from '@/store/feature-flag.store';

class FeatureFlagService {

    constructor() {
    }

    loadFeatureFlags() {
        return axios.get<FeatureFlagResponse[]>(EnvironmentService.applicationConfiguration.bookingServiceBaseUrl + "ContentManager/RMDFeatureFlags").then(response => {
            response.data.forEach(flag => {
                featureFlags.cache.push(new FeatureFlagResponse(flag.name, flag.value, flag.canaryDeploymentOptions))
            });
            return featureFlags;
        });
    }
}

const featureFlagService = new FeatureFlagService();
const loadFeatureFlags = () => featureFlagService.loadFeatureFlags();

export { loadFeatureFlags };
