import Vue from 'vue'
import VueI18n from 'vue-i18n'
import de from './de'
import pl from './pl'
import cz from './cz'

interface LocaleConfiguration {
    shortLocale: string; // Language code (eg en)
    longLocale: string; // Full locale code including language and country (eg en-GB)
    tmsLocales: Array<string>; // locale codes used in TMS
    currency: string; // currency to use for a locale
}

const messages = {
    de,
    pl,
    cz
};
const defaultLocale = "de";
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
    messages,
    silentFallbackWarn: true
});

const localeConfiguration: Array<LocaleConfiguration> = [
    {
        shortLocale: "de",
        longLocale: "de-DE",
        tmsLocales: [ "D", "DE"],
        currency: "EUR"
    }, 
    {
        shortLocale: "pl",
        longLocale: "pl-PL",
        tmsLocales: [  "PL" ],
        currency: "PLN"
    }, 
    {
        shortLocale: "cz",
        longLocale: "cs-CZ",
        tmsLocales: [ "CZ" ],
        currency: "CZK"
    }
];

export function resolveTmsLocale(tmsLocaleCode: string): string {
    const matchingLocale = localeConfiguration.find(l => l.tmsLocales.indexOf(tmsLocaleCode) > -1);
    return matchingLocale === undefined? localeConfiguration[0].shortLocale: matchingLocale.shortLocale;
}

export function getLongLocaleCode(shortLocaleCode: string): string {
    const matchingLocale = localeConfiguration.find(l => l.shortLocale === shortLocaleCode);
    return matchingLocale === undefined? localeConfiguration[0].longLocale: matchingLocale.longLocale;
}

export function getLongLocaleCodeMap(shortLocaleCode: string): string {
    if(shortLocaleCode == "cz") return "en-US"; //US 9788
    return getLongLocaleCode(shortLocaleCode);
}

export function getCurrencyCode(tmsLocaleCode: string): string {
    const matchingLocale = localeConfiguration.find(l => l.tmsLocales.indexOf(tmsLocaleCode) > -1);
    return matchingLocale === undefined? localeConfiguration[0].currency: matchingLocale.currency;
}

export default i18n;
