





















































import Vue from "vue";
import { Appointment, Day } from "@/components/modules/types/Calendar";
import CalendarHelper from "@/helpers/CalendarHelper";
const calendarHelper = new CalendarHelper();

interface CalendarData {
  selectedAppointmentId: string;
  selectedDate: string;
  selectedFormat: "weekly" | "monthly";
  selectedDay: string;
  isFullsize: boolean;
}

export default Vue.extend({
  components: {
    CalendarDay: () => import("@/components/modules/CalendarDay.vue"),
    CalendarWeek: () => import("@/components/modules/CalendarWeek.vue"),
    CalendarWeekTime: () => import("@/components/modules/CalendarWeekTime.vue"),
    //CalendarMonth: () => import ('@/components/modules/CalendarMonth.vue'),
    CalendarPager: () => import("@/components/modules/CalendarPager.vue"),
  },
  props: {
    appointments: calendarHelper.getAppointmentsPropValidator(),
  },
  data(): CalendarData {
    return {
      selectedAppointmentId: "",
      selectedDate: calendarHelper.getFirstAppointment(this.appointments),
      selectedFormat: "weekly",
      selectedDay: "",
      isFullsize: true,
    };
  },
  mounted() {
    window.addEventListener("resize", this.checkIfMobile);
    this.checkIfMobile();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
  computed: {
    selectedDayAppointments(): Array<Appointment> {
      return this.selectedDay
        ? this.appointments.filter((a) => a.date === this.selectedDay)
        : [];
    },
  },
  methods: {
    selectAppointment(appointment: Appointment) {
      this.selectedAppointmentId =
        this.selectedAppointmentId == appointment.id ? "" : appointment.id;
      this.$emit("change", this.selectedAppointmentId);
    },
    selectDay(day: Day) {
      if (day.appointments.length) this.selectedDay = day.date;
    },
    navigateDay(dir: 1 | -1) {
      if (!this.selectedDay) return;
      const date = calendarHelper.getMomentWithFormat(this.selectedDay);
      const searchWindowInDays = 60;

      for (let i = 1; i <= searchWindowInDays; i++) {
        const potentialNextDate =
          dir > 0
            ? date.clone().add(i, "days")
            : date.clone().subtract(i, "days");
        const potentialNextDateFormated = calendarHelper.getFormattedDate(potentialNextDate);
        const dateFound = this.appointments.some(
          (a) => a.date === potentialNextDateFormated
        );
        if (dateFound) {
          this.selectedDay = potentialNextDateFormated;
          return;
        }
      }
    },
    checkIfMobile() {
      this.isFullsize = window.matchMedia("(min-width: 1080px)").matches;
    },
  },
});
