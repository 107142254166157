import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import i18n from '@/i18n/index';
import { getAllRoutes } from '@/routeConfig';


Vue.use(VueRouter);

const router = new VueRouter({
	scrollBehavior: (to, from, savedPosition) => savedPosition ? savedPosition : { x: 0, y: 0 },
	mode: 'history',
	routes: getAllRoutes(),
});

//base: '/de',
router.beforeEach((to, from, next) => {

	// const lang = to.params.lang;
	if (to !== undefined && to.meta !== undefined && to.meta.disallowAuth && store.state.isLoggedIn) {

		// if user came from router-link
		if (from.name) return next(false);

		// if user navigated the site manually / from outside
		return next({ name: 'CorporateData' });
	}
	let locale = '';
	const localeUrlSegment = to.path.split('/'),
		currentLocale = i18n.locale;

	// Get locale from path
	localeUrlSegment.shift();
	locale = localeUrlSegment[0];

	// Locale fallback
	if (locale === '') locale = currentLocale;

	// Set locale
	i18n.locale = locale;
	to.params.locale = locale;

	return next();
});

export default router;
