













































import Vue from "vue";

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: "text",
      validator(val: string) {
        return ["text", "password", "email"].includes(val);
      },
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    message: {
      type: String,
    },
    pattern: {
      type: String,
    },
    minlength: {
      type: Number,
    },
    maxlength: {
      type: Number,
    },
    status: {
      type: String,
      default: "",
      validator(val: string) {
        return ["", "ok", "warning", "error"].includes(val);
      },
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      isFocused: false,
      inputId: "",
      inputName: "",
    };
  },
  created() {
    this.inputId =
      this.id ||
      "id" + window.crypto.getRandomValues(new Uint32Array(1)).toString();
    this.inputName = this.name || this.inputId;
  },
  computed: {
    isLabelAtop(): boolean {
      return this.isFocused || !!this.value;
    },
    statusClass(): string {
      return this.status
        ? "is" + this.status[0].toUpperCase() + this.status.substr(1)
        : "";
    },
    validity(): object {
      const inputElement = this.$refs.inputElement as HTMLFormElement;
      return inputElement.validity;
    },
  },
});
