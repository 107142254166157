import { extendMoment } from 'moment-range';
import moment, { Moment } from 'moment';
import i18n from '@/i18n/index';
import { Appointment } from "@/components/modules/types/Calendar";

const nameof = <T>(name: keyof T) => name;

export default class CalendarHelper {
  private actualDay = [] as Array<number>;
  private shiftValues = [] as Array<number>;
  private moment2 = extendMoment(require('moment'));
  private lastAppointments = [] as Array<any>;
  private dateFormat = "YYYY-MM-DD";
  private dateTimeFormat = "YYYY-MM-DDTHH:mm:ss"
  public readonly displayDateFormat = "DD.MM.YYYY";

  getMomentWithFormat(date: string) {
    return moment(date, this.dateFormat);
  }

  getFormattedDate(date?: string | Moment) {
    return moment(date).format(this.dateFormat);
  }

  getFormattedDateTime(date?: string | Moment) {
    return moment(date).format(this.dateTimeFormat);
  }

  getFormattedDateForDisplay(date?: string | Moment) {
    return moment(date).format(this.displayDateFormat);
  }

  getConvertedDateForDisplay(date: string) {
    return this.getMomentWithFormat(date).format(this.displayDateFormat);
  }

  getMaxAlternateLane(appointments: any[], overlappingTimes: number, daysToDisplay: number, selectedWeek: string) {
    this.lastAppointments = [];
    const nbsp = String.fromCharCode(160);
    const firstDayOfThisWeek = moment(selectedWeek).clone();
    const lastDayOfThisWeek = moment(selectedWeek)
      .clone()
      .add(daysToDisplay, "days");
    const appointmentsWithinWeek = appointments.filter(
      (a: Appointment) =>
        moment(a.start).isBetween(
          firstDayOfThisWeek,
          lastDayOfThisWeek,
          undefined,
          "[]"
        )
    );
    this.shiftValues.push(0);
    return appointmentsWithinWeek.map((appointment) => {
      const appointmentRange = this.moment2.range(
        appointment.start,
        appointment.end
      );

      if (
        this.getFormattedDate(appointment.start) ===
        firstDayOfThisWeek.format(this.dateFormat)
      ) {
        appointment.isRecommendation = true;
      }

      let clock = "";
      if (i18n.locale !== 'pl') {
        clock = i18n.t('general.clock') as string;
      }

      const times = {
        startHour: moment(appointment.start).hour(),
        endHour: moment(appointment.end).hour(),
        dayOfWeek: moment(appointment.start).isoWeekday(),
        timeDisplay:
          moment(appointment.start).format("HH:mm") +
          "<br /> - <br />" +
          nbsp +
          moment(appointment.end).format("HH:mm") +
          "<br/><br/>" +
          clock,
        alternateLane: 0 as number,
        actualPosition: 1,
      };

      //calculate actual position of appointment in calendar
      const actualDate = moment(appointment.start).clone();
      const startDate = moment(selectedWeek).clone();
      const difference = actualDate.diff(startDate, "days");
      times.actualPosition = difference + 1;

      //empty temp array if new weekday is calculated
      if (!this.actualDay.includes(times.dayOfWeek)) {
        this.actualDay.push(times.dayOfWeek);
        this.shiftValues = [];
        this.lastAppointments = [];
        this.shiftValues.push(0);
      }

      if (this.lastAppointments.length > 0) {
        for (let i = 0; i <= overlappingTimes - 1; i++) {
          /*check if actual appointment overlaps with preprocessed appointments, 
          if not the appointment gets the last possible AlternateLane*/
          const subSet = this.lastAppointments.filter(u => u.alternateLane === i);
          let flag = false;
          for (let j = 0; j <= subSet.length - 1; j++) {
            const appointmentRangeAlternative = this.moment2.range(
              subSet[j].start,
              subSet[j].end
            );
            if (appointmentRange.overlaps(appointmentRangeAlternative)) {
              flag = true;
              break;
            }
          }
          if (flag === false) {
            times.alternateLane = i;
            break;
          }
        }
        this.lastAppointments.push(times);
        this.shiftValues.push(times.alternateLane);
      } else {
        this.lastAppointments.push(times);
      }

      return Object.assign(times, appointment);
    });
  }

  calendarDisplayDays(overlappingTimes: number) {
    let daysToDisplay: number;
    switch (overlappingTimes) {
      case 1:
      case 2:
      case 3: {
        daysToDisplay = 7;
        break;
      }
      case 4: {
        daysToDisplay = 5;
        break;
      }
      case 5: {
        daysToDisplay = 4;
        break;
      }
      case 6: {
        daysToDisplay = 3;
        break;
      }
      case 7:
      case 8: {
        daysToDisplay = 2;
        break;
      }
      default: {
        daysToDisplay = 1;
      }
    }
    return daysToDisplay;
  }

  calendarPagerDaysTurn(overlappingTimes: number) {
    const daysTurn = this.calendarDisplayDays(overlappingTimes) - 1;
    return daysTurn;
  }

  getAppointmentsPropValidator(required = false) {
    return {
      type: Array as () => Appointment[],
      required: required,
      default: () => [],
      validator: (appointments: Array<Appointment>) => {
        return (
          appointments.every((appointment) =>
            ({}.hasOwnProperty.call(appointment, nameof<Appointment>("id")))
          ) &&
          appointments.every((appointment) =>
            ({}.hasOwnProperty.call(appointment, nameof<Appointment>("date")))
          ) &&
          appointments.every((appointment) =>
            ({}.hasOwnProperty.call(appointment, nameof<Appointment>("price")))
          ) &&
          appointments.every((appointment) =>
            ({}.hasOwnProperty.call(appointment, nameof<Appointment>("timeslot")))
          ) &&
          appointments.every((appointment) =>
            ({}.hasOwnProperty.call(appointment, nameof<Appointment>("isRecommendation")))
          )
        );
      },
    };
  }

  getFirstAppointment(appointments: Appointment[]) {
    const hasAppointments = typeof appointments !== 'undefined' && appointments.length > 0;
    return hasAppointments
      ? appointments[0].date
      : this.getFormattedDate();
  }
}
