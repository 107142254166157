<template>
  <div class="card" v-on="{ click: ctaCallback ? ctaCallback : null }">
    <img :src="image" :alt="title" class="card-image" />
    <div class="card-content">
      <div class="card-title">
        {{ title }}
      </div>
      <div class="card-description">
        <slot />
      </div>
      <RhButton
        priority="secondary"
        :value="ctaTitle"
        v-if="ctaTitle && ctaCallback"
        class="card-button"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    ctaTitle: {
      type: String,
    },
    ctaCallback: {
      type: Function,
    },
  },
});
</script>

<style lang="scss" scoped>
/*styling geändert da momentan nur eine card angezeigt wird. 
        Wenn später weitere cards auf der Übersichtsseite hinzukommen muss das styling wieder angepasst werden
        old:cards{ border: 1px solid $color-field-backdrop;
    width: 100%;max-width:300px display: flex;
    flex-direction: column;
    transition: box-shadow .2s ease;
    cursor: pointer;}
    */
.card {
  border: 1px solid $color-field-backdrop;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.2s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
  }

  &-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  &-content {
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &-title {
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $color-secondary-darkgray;
    margin-bottom: 20px;

    @include fit(600) {
      font-size: 1.5rem;
    }
  }

  &-description {
    margin-bottom: 25px;
  }

  &-button {
    margin-top: auto;
  }
}
</style>>
