




























import Vue from "vue";
import TheHeroLogin from "@/components/sections/TheHeroLogin.vue";

export default Vue.extend({
  components: {
    TheHeroLogin,
  },
});
