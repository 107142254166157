import { AxiosResponse } from "axios";
import { apiService, legacyApiService } from "@/services/ApiService";
import { featureFlags } from "@/store/feature-flag.store";
import { IBookableService, IBookableServicesApi, IBookServicesApi } from "@/store/types/Service";

export class BookableServiceService {

  public async GetBookableServices(
    payload: IBookableServicesApi
  ): Promise<AxiosResponse<IBookableService[]>> {
    let response: AxiosResponse;
    //TODO: new feature flag for bookable services
    if (featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_APPOINTMENT) {
      response = await apiService.get(
        `SelfScheduling/BookableServices/${payload.internalOrderId}`,
        {},
        "bookable_services"
      );
    } else {
      response = await legacyApiService.get(
        "api/services",
        payload,
        "bookable_services"
      );
    }

    return response.data;
  }

  public async BookServices(payload: IBookServicesApi): Promise<void>
  {
    if(featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_APPOINTMENT) {
      await apiService.post(`SelfScheduling/BookServices/${payload.bookServicesRequest.internalOrderId}`, { serviceKeys:  payload.bookServicesRequest.serviceKeys, articlesOnOrder: payload.articlesOnOrder }, 'book_services')
    }
    else
    {
      await legacyApiService.post('api/services/bookServices', payload, "book_services");
    }
  }
}



export const bookableServicesService = new BookableServiceService();
