import { IBookableService } from '@/store/types/Service';
import { IOrder } from '@/store/types/Order';

export default class BookableServiceFilter {
    
    private servicesToExcludePackagingDisposal =
        ["VerpackungsEntsorgung","VerpackungsEntsorgungXL","VerpackungsEntsorgungVP", "Sofamontage", "Moebelmontage", "Aufbauservice", "M"];
    private packagingArrayRemoval = ["VerpackungsEntsorgung","VerpackungsEntsorgungXL","VerpackungsEntsorgungVP"]
    /**
     *
     * This method decides if service 'Verpackungsentsorgung' can be offered
     * @param bookableServices
     * @param order
     */
    public getFilteredBookableServicesPackagingDisposal(bookableServices: IBookableService[], order: IOrder) {
      
        if (order.servicesCount > 0) {
            for (const servicePosition of order.servicePositionList) {
                for (const serviceToExcludePackagingDisposal of this.servicesToExcludePackagingDisposal) {
                    if (servicePosition.orderTypeVm.identifier === serviceToExcludePackagingDisposal) {
                     return this.filterServiceByKey(bookableServices,this.packagingArrayRemoval);
                    }
                }
            }
        }
        // No Filter for DeliveryOrder & Exchange Order
        if (order.deliveryArticleCount > 0) {
            return bookableServices;
        }

        // Filter if PickupOrder
        if (order.pickUpArticleCount > 0) {
            return this.filterServiceByKey(bookableServices, this.packagingArrayRemoval);
        }
        return bookableServices;
    }
     /**
     * ENDCP-208
     * This method decides if service 'ZusätzlicheLeistung' can be offered
     * @param bookableServices
     * @param order
     */
      public getFilteredBookableServicesAdditionalService(bookableServices: IBookableService[], order: IOrder) {
        let country;
        if(order.address.country !== null){
            country = order.address.country.toLowerCase();
        }

        if(country !== 'de' && country !== 'd'){
            bookableServices = this.filterServiceByKey(bookableServices, ['ZusätzlicheLeistung']);
        }
        
        return bookableServices;
    }

    //return filtered bookable service list
    private filterServiceByKey(bookableServices: IBookableService[], BookableServiceFilter: string[]) {

        const result = bookableServices.filter(bookableService => !BookableServiceFilter.includes(bookableService.key));
        
        return result;
    }
}

