






import Vue from "vue";

export default Vue.extend({
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeOther(component: Vue) {
      if (this.multiple) return;
      this.$children.forEach((item) => {
        if (item !== component) {
          (item as any).isExtended = false;
        }
      });
    },
  },
});
