
























import Vue from "vue";
import Stepper from "@/components/modules/Stepper.vue";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default Vue.extend({
  components: {
    Stepper,
  },
  data() {
    return {
      isLoading: false,
      isLoadingAppointments: false,
    };
  },
  computed: {
    ...mapGetters(["getOrder"]),
    ...mapState(["deliveryRestriction"]),
  },
  methods: {
    async sendRestriction() {
      try {
        this.isLoading = true;

        await this.setDeliveryRestriction();
        this.SET_DELIVERY_RESTRICTION(true);

      } catch (error) {
        console.error("Error setting delivery restriction:", error);
      } finally {
        this.isLoading = false;
        this.$navigateTo("deliveryRestrictionSuccess");
      }
    },
    getAppointments() {
      this.isLoadingAppointments = true;
      this.requestCustomerAppointments()
        .finally(() => {
          this.isLoadingAppointments = false;
          this.$navigateTo("appointmentSelection");
        });
    },
    ...mapActions(["requestCustomerAppointments", "setDeliveryRestriction"]),
    ...mapMutations(["SET_DELIVERY_RESTRICTION", "SET_APPOINTMENT_ERROR"]),
  },
});
