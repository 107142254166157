import { render, staticRenderFns } from "./Impressum.vue?vue&type=template&id=491d8027&scoped=true&"
var script = {}
import style0 from "./Impressum.vue?vue&type=style&index=0&id=491d8027&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "491d8027",
  null
  
)

export default component.exports