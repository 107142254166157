import { IDeliveryRestrictionRequest } from "@/store/types/Appointment";
import { AxiosResponse } from "axios";
import {apiService, legacyApiService} from "@/services/ApiService";
import {featureFlags} from "@/store/feature-flag.store";

export class DeliveryRestrictionService {
    public async SetDeliveryRestriction(payload: IDeliveryRestrictionRequest) {
        let response: AxiosResponse;
        if (featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_DELIVERY_RESTRICTION) {
            response = await apiService.post(
                `SelfScheduling/DeliveryRestriction/${payload.internalOrderId}`,
                {},
                "delivery_restriction"
            );
        }
        else {
            response = await legacyApiService.post("api/appointment/deliveryRestriction",
                payload,
                "delivery_restriction");
        }

        return response.data;
    }
}

export const deliveryRestrictionService = new DeliveryRestrictionService();
