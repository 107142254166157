






















































import Vue from "vue";

export default Vue.extend({
  props: {
    options: {
      type: Array as () => IOption[],
      required: true,
      default: [],
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prefill: {
      type: String,
    },
    message: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      isFocused: false,
      inputId: "",
      inputName: "",
      value: "",
    };
  },
  created() {
    this.inputId =
      this.id ||
      "id" + window.crypto.getRandomValues(new Uint32Array(1)).toString();
    this.inputName = this.name || this.inputId;
    this.value = this.prefill;
  },
  computed: {
    isLabelAtop(): boolean {
      return this.isFocused || !!this.value;
    },
    validity(): object {
      const inputElement = this.$refs.selectElement as HTMLFormElement;
      return inputElement.validity;
    },
  },
  methods: {
    change(event: { target: HTMLInputElement }) {
      const value = event.target.value;
      this.value = value;
      this.$emit(
        "input",
        this.options.find((opt) => opt.id === value)
      );
    },
  },
});
