var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input"},[_c('div',{staticClass:"field",class:[
              {
                  isLabelAtop: _vm.isLabelAtop,
                  hasLabel: _vm.label,
                  isRequired: _vm.required,
                  isDisabled: _vm.disabled
              },
              _vm.statusClass
          ]},[(_vm.label)?_c('label',{attrs:{"for":_vm.inputId}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('input',{ref:"inputElement",attrs:{"name":_vm.inputName,"id":_vm.inputId,"type":_vm.type,"required":_vm.required,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"pattern":_vm.pattern,"minlength":_vm.minlength,"maxlength":_vm.maxlength},domProps:{"value":_vm.value},on:{"focus":function($event){_vm.isFocused = true},"blur":function($event){_vm.isFocused = false;
                  _vm.$emit('blur');},"input":function($event){return _vm.$emit('input', $event.target.value)}}}),_c('transition',{attrs:{"name":"trans-icon"}},[(_vm.status === 'ok')?_c('svg',{key:'icon-ok',class:'icon',attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2m-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"}})]):(_vm.status === 'warning')?_c('svg',{key:'icon-warning',class:'icon',attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M13 14h-2v-4h2m0 8h-2v-2h2M1 21h22L12 2 1 21z"}})]):(_vm.status === 'error')?_c('svg',{key:'icon-error',class:'icon',attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M13 13h-2V7h2m-1 10.3a1.3 1.3 0 01-1.3-1.3 1.3 1.3 0 011.3-1.3 1.3 1.3 0 011.3 1.3 1.3 1.3 0 01-1.3 1.3M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3z"}})]):_vm._e()])],1),_c('transition',{attrs:{"name":"trans-message"}},[(_vm.message)?_c('div',{class:{ error: _vm.statusClass === 'isError' }},[_vm._v(_vm._s(_vm.message))]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }