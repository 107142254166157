import { featureFlags } from "@/store/feature-flag.store";
import { AxiosResponse } from "axios";
import { apiService, legacyApiService } from "@/services/ApiService";

export class ContactFormService {
  public async sendContactFormData(payload: IContactRequest) {
    let response: Promise<AxiosResponse>;

    if (featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_CONTACT_FORM) {
      response = apiService.post(
        "SelfScheduling/ContactRequest",
        payload,
        "contact_form_data_sent"
      );
    } else {
      response = legacyApiService.post(
        "api/contact",
        payload,
        "contact_form_data_sent"
      );
    }

    return response;
  }
}

export const contactFormService = new ContactFormService();
