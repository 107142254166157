
























import Vue from "vue";

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    center: {
      type: Boolean,
      default: false,
    },
    emblemTop: {
      type: Boolean,
      default: false,
    },
    isHero: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: "small",
      validator: (value) => ["normal", "small", "tall"].includes(value),
    },
    width: {
      type: String,
      default: "normal",
      validator: (value) => ["normal", "small", "narrow"].includes(value),
    },
  },
});
