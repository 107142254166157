











import Vue from "vue";

export default Vue.extend({
  props: {
    steps: {
      type: Number,
      default: 10,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  }, //,
  //created(){

  //},
  //computed: {

  //},
  //mounted(){

  //},
  //methods: {

  //}
});
