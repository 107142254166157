
import Vue from 'vue'

export default Vue.extend({
    name: "RhSlider",
    data() {
        return {
            touch: {
                initialX: null as null | number,
                initialY: null as null | number,
            },
            currentIndex: 0,
        }
    },
    computed: {
        children() {
            return this.$slots.default || [];
        },
    },
    mounted() {
        // set initial slide
        this.currentIndex = 0;
        const nextButton = document.querySelector(".button-right");
        const prevButton = document.querySelector(".button-left");
        const container = document.querySelector("#slider");
        const children = document.querySelectorAll("#slider>*:not(.slider-button)");
        children.forEach((child, index) => {
            if (!index) {
                child.classList.add("showing");
            }
            child.classList.add("slide");
        });

        if (container && nextButton && prevButton) {
            // eslint-disable-next-line
            container.addEventListener("touchstart", this.startTouch, false);
            // eslint-disable-next-line
            container.addEventListener("touchmove", this.moveTouch, false);
            // eslint-disable-next-line
            nextButton.addEventListener("click", () => {
                this.nextCard();
            });
            // eslint-disable-next-line
            prevButton.addEventListener("click", () => {
                this.prevCard();
            });
        }
    },
    methods: {
        startTouch: function (e: Event) {
            const touchEvent = e as TouchEvent;
            const startTouch = touchEvent.touches[0];

            this.touch.initialX = startTouch.clientX;
            this.touch.initialY = startTouch.clientY;
        },
        moveTouch: function (e: Event) {
            const touchEvent = e as TouchEvent;
            if (this.touch.initialX === null || this.touch.initialY === null) {
                return;
            }

            const currentX = touchEvent.touches[0].clientX;
            const currentY = touchEvent.touches[0].clientY;

            const diffX = this.touch.initialX - currentX;
            const diffY = this.touch.initialY - currentY;

            if (Math.abs(diffX) > Math.abs(diffY)) {
                // sliding horizontally
                if (diffX > 0) {
                    // swiped left
                    this.nextCard();
                } else {
                    // swiped right
                    this.prevCard();
                }
            }
            this.touch.initialX = null;
            this.touch.initialY = null;
            e.preventDefault();
        },
        nextCard() {
            const children = document.querySelectorAll("#slider .slide");
            // current slide becomes hidden
            children[this.currentIndex].classList.remove("showing");
            // set the current slide as the next one
            this.currentIndex = (this.currentIndex + 1) % children.length;
            // add the class showing to the slide to make it visible
            children[this.currentIndex].classList.add("showing");
        },
        prevCard() {
            const children = document.querySelectorAll("#slider .slide");
            //hide current slide
            children[this.currentIndex].classList.remove("showing");
            // set the current slide as the previous one
            this.currentIndex = (this.currentIndex - 1) % children.length;

            if (this.currentIndex == -1) {
                this.currentIndex = children.length - 1;
            }
            children[this.currentIndex].classList.add("showing");
        },
    }
})
