export { }

declare global {
    interface Date {
        /** Returns a copy of this instance. */
        copy(): Date;
        /** Gets the pure time value (h:m:s) in milliseconds. */
        getTimeWithoutDate(): number;
        /**
         * Returns a new date object that adds the specified number of days to the value of this instance, using local time.
         * @param days A numeric value representing days.
         */
        addDays(days: number): Date;
        /**
         * Returns a new date object that adds the pure time (h:m:s) of the specified date to the value of this instance, using local time.
         * @param dateTime A date object.
         */
        addTime(dateTime: Date): Date;
        /**
         * Returns a new date object that adds the specified number of hours to the value of this instance, using local time.
         * @param hours A numeric value representing hours.
         */
        addHours(hours: number): Date;
        /**
         * Returns a new date object that adds the specified number of minutes to the value of this instance, using local time.
         * @param minutes A numeric value representing minutes.
         */
        addMinutes(minutes: number): Date;
        /**
         * Returns a date as a string value appropriate to the host environment's current locale.
         * @param withTime A boolean value indicating if the time should be part of the result.
         * @param withSeconds A boolean value indicating if seconds should be part of the result.
         */
        toLocaleDate(withTime?: boolean, withSeconds?: boolean): string;
        /**
         * Returns a date as a string value appropriate to the host environment's current locale (including the weekday).
         * @param short A boolean value indicating if the weekday should be short.
         * @param withTime A boolean value indicating if the time should be part of the result.
         * @param withSeconds A boolean value indicating if seconds should be part of the result.
         */
        toLocaleDateWithWeekday(short?: boolean, withTime?: boolean, withSeconds?: boolean): string;
        /** Returns a time as a string value appropriate to the host environment's current locale.
         * @param withSeconds A boolean value indicating if seconds should be part of the result.
         */
        toLocaleTime(withSeconds?: boolean): string;
        /** Returns a Date with 00:00:00 Time
         * 
         */
        getDateWithZeroTime(): Date;
    }
}

const secondsMultiplier = 1000;
const minutesMultiplier = secondsMultiplier * 60;
const hoursMultiplier = minutesMultiplier * 60;

Date.prototype.copy = function () {
    return new Date(this.valueOf());
}

Date.prototype.getTimeWithoutDate = function () {
    let time = this.getHours() * hoursMultiplier;
    time += this.getMinutes() * minutesMultiplier;
    time += this.getSeconds() * secondsMultiplier;

    return time;
}

Date.prototype.addDays = function (days) {
    const date = this.copy();
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.addTime = function (dateTime) {
    const timeWithoutDate = dateTime.getTimeWithoutDate();

    const date = this.copy();
    date.setTime(this.getTime() + timeWithoutDate);
    return date;
}

Date.prototype.addHours = function (hours) {
    const date = this.copy();
    date.setTime(this.getTime() + (hours * hoursMultiplier));
    return date;
}

Date.prototype.addMinutes = function (minutes) {
    const date = this.copy();
    date.setTime(this.getTime() + (minutes * minutesMultiplier));
    return date;
}

const getDateOptions = () => ({
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
} as Intl.DateTimeFormatOptions);

Date.prototype.toLocaleDate = function (withTime, withSeconds) {
    const options = getDateOptions();

    let localeDate = this.toLocaleDateString([], options);

    if (withTime) {
        const localeTime = this.toLocaleTime(withSeconds);
        localeDate += ` ${localeTime}`;
    }

    return localeDate;
}

Date.prototype.toLocaleDateWithWeekday = function (short, withTime, withSeconds) {
    const options = getDateOptions();
    options.weekday = short ? 'short' : 'long';

    let localeDate = this.toLocaleDateString([], options);

    if (withTime) {
        const localeTime = this.toLocaleTime(withSeconds);
        localeDate += ` ${localeTime}`;
    }

    return localeDate;
}

Date.prototype.toLocaleTime = function (withSeconds) {
    const options = {
        hour: "2-digit",
        minute: "2-digit"
    } as Intl.DateTimeFormatOptions;

    if (withSeconds)
        options.second = "2-digit";

    return this.toLocaleTimeString([], options);
}

Date.prototype.getDateWithZeroTime = function () {
    const date = this.copy();
    date.setHours(0, 0, 0, 0);
    return date;
}
