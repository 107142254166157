import Vue from "vue";
import moment from "moment";
import i18n, { getLongLocaleCode } from '@/i18n/index';

Vue.filter("formatDate", (date: any) => moment(date).format("DD.MM.YYYY"));
Vue.filter("formatTime", (time: any) => moment(time, "hh:mm A").format("HH:mm"));
Vue.filter("formatDateWithTime", (dateWithTime: any) => moment(dateWithTime).format("DD.MM.YYYY HH:mm"));
Vue.filter("formatAmount",
  function (value: any, arg: string) {
    const euro = value / 100;
    const localeString = getLongLocaleCode(i18n.locale);
    const formattedValue = euro.toLocaleString(localeString,
      {
        style: "currency",
        currency: arg,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      });
    return formattedValue;
  });
