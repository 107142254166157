























































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import "@/extensions/StringExtensions.ts";
import i18n from "@/i18n/index";

export default Vue.extend({
  computed: {
    ...mapGetters(["getService", "getOrder", "getMakingOrShowingAppointment", "getCanMakeAppointment"]),
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    getClock() {
      if (i18n.locale === "pl") {
        return "";
      }
      return this.$t("general.clock");
    },
    async checkSchedulability() {
      return await this.requestCanMakeAppointment();
    },
    loadAppointments() {
      if(this.getCanMakeAppointment) {
        this.requestCustomerAppointments()
          .finally(() => {
            this.$navigateDirectTo("deliveryRestriction");
          });
      } else {
        this.$navigateTo("contact"); 
      }
    },
    navigateToAppointmentBooking() {
      this.isLoading = true;
      try {
      this.checkSchedulability()
        .then(() => {
          this.loadAppointments();
        })
      } catch {
        this.$navigateTo("contact"); 
      }
    },
    ...mapActions(["requestCustomerAppointments", "requestCanMakeAppointment"]),
  },
});
