import {load, ReCaptchaInstance} from 'recaptcha-v3';
import EnvironmentService from "@/services/EnvironmentService";

class ReCaptchaService {
    private _reCaptcha?: ReCaptchaInstance;
    private _loadPromise?: Promise<ReCaptchaInstance>;

    private load() {
        this._loadPromise = load(EnvironmentService.applicationConfiguration.recaptchaKey);
    }

    async execute(action: string) {
        if (!this._reCaptcha) {
            if (!this._loadPromise) {
                this.load();
            }
            this._reCaptcha = await this._loadPromise;
        }
        if (!this._reCaptcha) {
            throw new Error("reCaptcha could not be loaded");
        }
        return this._reCaptcha.execute(action);
    }
}

const reCaptchaService = new ReCaptchaService();

export {reCaptchaService}
