/**
 * Sets the config for the App
 * ! Taken as it is from BaseApp, but may better be suited as environment variables for the building process
 */

export type ApplicationConfiguration = {
    name: string;
    baseUrl: string;
    isMocking: boolean;
    contactApiPath: string;
    additionalServicesMailApiPath: string;
    defaultCountryCodeAndFormat: string;
    hereApiKey: string;
    hereLg: string;
    hereLocalization: string;
    recaptchaKey: string;
    clientsExcludedFromTrustPilot: string;
    clientsExcludedFromContactForm: string;
    inactivityTimeout: number;
    bookingServiceBaseUrl: string;
}

const AppConfig = Object({
    name: 'Rhenus.SN.CJM.EndCustomerPortal',
    baseUrl: process.env.VUE_APP_API_URL,
    isMocking: false,
    contactApiPath: 'api/contact',
    additionalServicesMailApiPath: 'api/services/additionalService',
    defaultCountryCodeAndFormat: 'DE',
    hereApiKey: process.env.VUE_APP_HERE_KEY,
    hereLg: 'de',
    hereLocalization: 'de-DE',
    recaptchaKey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
    clientsExcludedFromTrustPilot: process.env.VUE_APP_CLIENTS_EXCLUDED_FROM_TRUSTPILOT,
    clientsExcludedFromContactForm: process.env.VUE_APP_CLIENTS_EXCLUDED_FROM_CONTACTFORM,
    inactivityTimeout: process.env.VUE_APP_INACTIVITY_TIMEOUT,
    bookingServiceBaseUrl: process.env.VUE_APP_BOOKING_SERVICE_URL,
}) as ApplicationConfiguration;

export default AppConfig;
