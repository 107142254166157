

















import Vue, { VNode } from "vue";

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: "button",
      validator(val: string) {
        return ["submit", "button", "reset"].includes(val);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
    },
    priority: {
      type: String,
      default: "ghost",
      validator(val: string) {
        return ["primary", "secondary", "ghost", "ghost-inverse"].includes(val);
      },
    },
  },
  computed: {
    hasIcon() {
      return !!(this.$slots as { [key: string]: VNode[] }).default;
    },
  },
  methods: {
    click() {
        if(this.$props.isLoading) {
            return;
        }
        this.$emit("click");
    },
  },
});
