


















import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "getStepperValue",
    ]),
  },
  methods: {
    getStepping: function (): string {
      const stepping = this.currentStep + " " + this.$t("general.from") + " " + this.getStepperValue;
      const steppingText = this.$t("general.steps").toString();

      return steppingText.replace("##stepping##", stepping);
    },
  }
});
