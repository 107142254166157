































import Vue from "vue";
import i18n from "@/i18n/index";
import { mapState } from "vuex";
import { getRouteName } from "@/routeConfig";
interface InternalLink {
  title: string;
  name?: string;
  url?: string;
}
interface InternalLinkWithName extends InternalLink {
  name: string;
}
interface InternalLinkWithUrl extends InternalLink {
  url: string;
}

interface SocialLink {
  name: string;
  url: string;
}

declare const OneTrust: any;

export default Vue.extend({
  data() {
    return {
      // Rhenus-internal links, have a title and either a url or name, depending
      // whether it's a site-internal or site-external leading link
      linksInternal: [
        {
          title: this.$t("general.impressum"),
          name: getRouteName("imprint"),
        } as InternalLinkWithName,
        {
          title: this.$t("general.privacy"),
          name: getRouteName("privacy"),
        } as InternalLinkWithName,
        {
          title: this.$t("general.aboutUs"),
          url: "https://www.rhenus.group/customised-supply-chain/home-delivery",
        } as InternalLinkWithUrl,
        {
          title: this.$t("general.faq"),
          name: getRouteName("faq"),
        } as InternalLinkWithName,
      ] as InternalLink[],

      // Rhenus social media channels; besides using the name as a key, it's used to
      // gather the social media icon dynamically from assets/icons/socialmedia
      // make sure, there's a corresponding svg icon there.
      linksSocial: [
        {
          name: "linkedin",
          url: "https://www.linkedin.com/company/rhenus-home-delivery-gmbh/?viewAsMember=true",
        },
        {
          name: "xing",
          url: "https://www.xing.com/companies/rhenushomedeliverygmbh",
        },
      ] as SocialLink[],
    };
  },
  created() {
    const currentLocale = i18n.locale;
    if (currentLocale === "de") {
      this.linksInternal[2].url =
        "https://www.rhenus.group/de/customised-supply-chain/home-delivery";
    } else if (currentLocale === "pl") {
      this.linksInternal[2].url =
        "https://www.rhenus.com/pl/pl/uslugi/home-delivery";
    } else {
      this.linksInternal[2].url =
        "https://www.rhenus.group/customised-supply-chain/home-delivery";
    }
  },
  computed: {
    ...mapState(["language"]),
  },
  watch: {
    language(actualLanguage) {
      if (actualLanguage === "de") {
        this.linksInternal[2].url =
          "https://www.rhenus.group/de/customised-supply-chain/home-delivery/";
      } else if (actualLanguage === "pl") {
        this.linksInternal[2].url =
          "https://www.rhenus.com/pl/pl/uslugi/home-delivery";
      } else {
        this.linksInternal[2].url =
          "https://www.rhenus.group/customised-supply-chain/home-delivery";
      }
      this.linksInternal[0].title = this.$t("general.impressum").toString();
      this.linksInternal[1].title = this.$t("general.privacy").toString();
      this.linksInternal[2].title = this.$t("general.aboutUs").toString();
      this.linksInternal[3].title = this.$t("general.faq").toString();
      this.linksInternal[0].name = getRouteName("imprint");
      this.linksInternal[1].name = getRouteName("privacy");
      this.linksInternal[3].name = getRouteName("faq");
    },
  },
  methods: {
    getLinksInternal() {
      if (i18n.locale === "de") {
        return this.linksInternal;
      } else {
        return [
          this.linksInternal[0],
          this.linksInternal[1],
          this.linksInternal[2],
        ];
      }
    },
    getLinksSocial() {
      if (i18n.locale === "de") {
        return this.linksSocial;
      } else {
        return [];
      }
    },
    showCookieSettings() {
      OneTrust.ToggleInfoDisplay();
    }
  },
});
