import {IOrderRequest} from "@/store/types/Order";
import {featureFlags} from "@/store/feature-flag.store";
import {apiService, legacyApiService} from "@/services/ApiService";
import {OrderDetailResponse, OrderDetailResponseWithLegacy} from "@/services/types/OrderDetailResponse";
import {IFundamentalData} from "@/store/types/IState";
import {AxiosResponse} from "axios";

export class OrderDetailService {
    private async GetOrderDetail(apiCall: () => Promise<AxiosResponse<OrderDetailResponse>>, payload: IOrderRequest): Promise<OrderDetailResponseWithLegacy> {
        const legacyResponse = await legacyApiService.post<IFundamentalData>('api/order', payload, 'login');
        if (!legacyResponse.data.loginSuccessful) {
            return {
                isOk: false,
                legacyData: legacyResponse.data,
            }
        }
        featureFlags.setClientKey(legacyResponse.data.order.clientKey!);
        if (featureFlags.useCloudEndpointForLogin) {
            const response = await apiCall();
            return {
                isOk: response.data.isOk,
                order: response.data.order,
                legacyData: legacyResponse.data
            };
        }

        return {
            isOk: legacyResponse.data.loginSuccessful,
            legacyData: legacyResponse.data
        }
    }

    public async GetByOrderNumberAndPostalCode(
        payload: IOrderRequest
    ) {
        const apiCall = () => apiService.get<OrderDetailResponse>(
            `SelfScheduling/Order/OrderNumber/${payload.externalOrderNumber}/PostalCode/${payload.postcode}`,
            {},
            'login');
        return this.GetOrderDetail(apiCall, payload);
    }

    public async GetByOrderNumberAndHash(
        payload: IOrderRequest
    ) {
        const apiCall = () => apiService.get<OrderDetailResponse>(
            `SelfScheduling/Order/OrderNumber/${payload.externalOrderNumber}/Hash/${payload.hash}`,
            {},
            'login'
        );
        return this.GetOrderDetail(apiCall, payload);
    }
}

export const orderDetailService = new OrderDetailService();
