export default class SeasonHelper {
  
  static getSeason(currentDate: Date) {
    const year = currentDate.getFullYear();
    const easterMonday = this.getEasterMonday(year);
    const easterSart = this.getEasterStart(new Date(easterMonday)); // 3 Weeks before easterMonday
    const firstAdvent = this.getFirstAdvent(year);
    const endOfChristmas = 27; //represent the day
    const christmasEnd = new Date(year, 11, endOfChristmas);

    return this.returnSeasonString(
      currentDate,
      easterMonday,
      easterSart,
      christmasEnd,
      firstAdvent
    );
  }

  static getEasterMonday(year: number) {
    //https://gist.github.com/johndyer/0dffbdd98c2046f41180c051f378f343 --> formular to calculate easter sunday
    const easterMondayIncrement = 1;
    const f = Math.floor;
    const G = year % 19;
    const C = f(year / 100);
    const H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30;
    const I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11));
    const J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7;
    const L = I - J;
    const month = 3 + f((L + 40) / 44);
    const day = L + 28 - 31 * f(month / 4) + easterMondayIncrement;

    return new Date(year, month - 1, day);
  }

  static getEasterStart(easterMondayDate: Date) {
    const easterStartDate = easterMondayDate;
    const easterTimeSpan = 21;
    easterStartDate.setDate(easterMondayDate.getDate() - easterTimeSpan);

    return easterStartDate;
  }

  static getFirstAdvent(year: number) {
    const firstAdvent = new Date(year, 11, 3);
    firstAdvent.setDate(firstAdvent.getDate() - firstAdvent.getDay());

    return firstAdvent;
  }

  static returnSeasonString(
    currentDate: Date,
    easterMonday: Date,
    easterStart: Date,
    christmasEndDate: Date,
    firstAdventDate: Date
  ) {
    if (currentDate >= easterStart && currentDate <= easterMonday) {
      return "Easter";
    } else if (
      currentDate >= firstAdventDate && currentDate <= christmasEndDate) {
      return "Christmas";
    } else {
      return "Default";
    }
  }
}