import {FeatureFlagResponse} from "@/services/types/FeatureFlagResponse";

export class FeatureFlagStore {
    private _clientKey = '';
    cache: FeatureFlagResponse[] = [];

    setClientKey(clientKey: string) {
        this._clientKey = clientKey;
    }

    getFeatureFlag(name: string) {
        const featureFlag = this.cache.find(f => f.name === name);
        if (!featureFlag) {
            return false;
        }

        if (!featureFlag.value) {
            return false;
        }

        return this._clientKey === '' || !featureFlag.canaryDeploymentOptions.length || featureFlag.canaryDeploymentOptions.includes(this._clientKey);
    }

    get BOOKING_RMD_USECLOUDENDPOINTS_DELIVERY_RESTRICTION() {
        return this.getFeatureFlag("BOOKING_RMD_USECLOUDENDPOINTS_DELIVERY_RESTRICTION");
    }

    get BOOKING_RMD_USECLOUDENDPOINTS_APPOINTMENT() {
        return this.getFeatureFlag("BOOKING_RMD_USECLOUDENDPOINTS_APPOINTMENT");
    }

    get BOOKING_RMD_USECLOUDENDPOINTS_CONTACT_FORM() {
        return this.getFeatureFlag("BOOKING_RMD_USECLOUDENDPOINTS_CONTACT_FORM");
    }

    get useCloudEndpointForLogin() {
        return this.BOOKING_RMD_USECLOUDENDPOINTS_DELIVERY_RESTRICTION || this.BOOKING_RMD_USECLOUDENDPOINTS_APPOINTMENT 
            || this.BOOKING_RMD_USECLOUDENDPOINTS_TRACKING;
    }

    get BOOKING_RMD_HIDE_DELIVERY_RESTRICTION() {
        return this.getFeatureFlag("BOOKING_RMD_HIDE_DELIVERY_RESTRICTION");
    }

    get BOOKING_RMD_USECLOUDENDPOINTS_TRACKING() {
        return this.getFeatureFlag("BOOKING_RMD_USECLOUDENDPOINTS_TRACKING");
    }

    get BOOKING_RMD_USECLOUDENDPOINTS_STATUS_TRACKING() {
        return this.getFeatureFlag("BOOKING_RMD_USECLOUDENDPOINTS_STATUS_TRACKING");
    }
}
