import axios, { AxiosError } from 'axios';
import EnvironmentService from './EnvironmentService';

export default class LoggerService {
  private readonly useLongConsoleText: boolean;
  private readonly category: string = EnvironmentService.applicationConfiguration.name;
  private readonly console: any;

  /**
   * Construct a new logger
   * @param category Category of the logger (mostly the component or the service name)
   * @param useLongConsoleText long console text with date, log level and category
   */
  constructor(category: string, useLongConsoleText = true, consoleProxy?: any) {
    this.category += `.${category}`;
    this.useLongConsoleText = useLongConsoleText;
    if (!consoleProxy) {
      this.console = console;
    } else {
      this.console = consoleProxy;
    }
  }

  /**
   * Log Debug information
   * @param message The message
   * @param parameters Additional parameters
   */
  public logDebug(message: string, ...parameters: any[]): void {
    this.handleLog('debug', 'Debug', message, ...parameters);
  }

  /**
   * Log information
   * @param message The message
   * @param parameters Additional parameters
   */
  public logInformation(message: string, ...parameters: any[]): void {
    this.handleLog('info', 'Information', message, ...parameters);
  }

  /**
   * Log warning
   * @param message The message
   * @param parameters Additional parameters
   */
  public logWarning(message: string, ...parameters: any[]): void {
    this.handleLog('warn', 'Warning', message, ...parameters);
  }

  /**
   * Log error
   * @param message The message
   * @param parameters Additional parameters
   */
  public logError(message: string, ...parameters: any[]): void {
    this.handleLog('error', 'Error', message, ...parameters);
  }

  /**
   * Log critical
   * @param message The message
   * @param parameters Additional parameters
   */
  public logCritical(message: string, ...parameters: any[]): void {
    this.handleLog('error', 'Critical', message, ...parameters);
  }

  /**
   * Handle the log entry
   * @param consoleMethodName The console function name to call
   * @param logLevel The log level
   * @param message The message
   * @param parameters Additional data
   */
  private handleLog(consoleMethodName: string, logLevel: string, message: string, ...parameters: any[]): void {
    this.logToConsole(consoleMethodName, logLevel, message, ...parameters);
    this.logToServer(logLevel, message, ...parameters);
  }

  /**
   * Log to the console
   * @param consoleMethodName The console function name to call
   * @param logLevel The log level
   * @param message The message
   * @param parameters Additional data
   */
  private logToConsole(consoleMethodName: string, logLevel: string, message: string, ...parameters: any[]): void {
    let consoleMessage = message;
    if (this.useLongConsoleText) {
      consoleMessage = `${new Date().toISOString()} [${logLevel.substring(0, 3).toUpperCase()}] [${this.category}] ${message}`;
    }

    if (parameters && parameters.length > 0) {
      this.console[consoleMethodName](consoleMessage, parameters);
      return;
    }

    this.console[consoleMethodName](consoleMessage);
  }

  /**
   * Log to the server with axios
   * @param logLevel The log level
   * @param message The message
   * @param parameters Additional data
   */
  private logToServer(logLevel: string, message: string, ...parameters: any[]): void {
    if (!EnvironmentService.applicationConfiguration.baseUrl) {
      return;
    }
    const url = `${EnvironmentService.applicationConfiguration.baseUrl}api/log/${logLevel.toLowerCase()}`;
    const params: any = {
      category: this.category,
      message,
    };
    if (parameters && parameters.length > 0) {
      params.args = JSON.stringify(parameters);
    }

    axios.post(url, params)
      .catch((error: AxiosError) => console.error('Failed to log to server:', error));
  }
}
