<template>
  <div class="status">
    <template v-for="(stage, index) in stages">
      <div class="status-stage" :class="'is-' + stage.status" :key="stage.name">
        <div class="status-icon">
          <slot :name="stage.name" />
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-if="stage.status === 'success'" v-bind:class="'status-icon-badge'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2m-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-if="stage.status === 'warning'" v-bind:class="'status-icon-badge'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13 14h-2v-4h2m0 8h-2v-2h2M1 21h22L12 2 1 21z"/></svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-if="stage.status === 'error'" v-bind:class="'status-icon-badge'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12 6.47 2 12 2m3.59 5L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41 15.59 7z"/></svg>
        </div>
        <div class="status-title">{{ stage.title }}</div>
        <div class="status-date" v-if="stage.status">{{ stage.date }}</div>
      </div>
      <div
        v-if="index < stages.length - 1"
        :key="stage.name + '_arrow'"
        class="status-arrow"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 25" transform="scale(1.5)" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.59 16.58L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42z"/></svg>
      </div>
    </template>
    <div class="status-latest">
      <div class="status-title">{{ latestStageWithStatus.title }}</div>
      <div class="status-date">{{ latestStageWithStatus.date }}</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    stages: {
      type: Array,
      required: true,
      validator: (stages) => {
        return stages.every((stage) => {
          const hasOwnPropertyName = Object.prototype.hasOwnProperty.call(
            stage,
            "name"
          );
          const hasOwnPropertyTitle = Object.prototype.hasOwnProperty.call(
            stage,
            "title"
          );
          const hasOwnPropertyDate = Object.prototype.hasOwnProperty.call(
            stage,
            "date"
          );
          const hasOwnPropertyStatus = Object.prototype.hasOwnProperty.call(
            stage,
            "status"
          );

          return (
            hasOwnPropertyName &&
            hasOwnPropertyTitle &&
            hasOwnPropertyDate &&
            hasOwnPropertyStatus
          );
        });
      },
    },
  },
  computed: {
    latestStageWithStatus() {
      // Premiss is that there are no gaps of status' inbetween stages
      for (let i = 0; i < this.stages.length; i++) {
        const stage = this.stages[i];
        if (stage.status === "") {
          // return first, if no stage has a status
          if (i === 0) return stage;
          // return last entry with status
          else return this.stages[i - 1];
        }
      }
      // return last, if all stages have a status
      return this.stages[this.stages.length - 1];
    },
  },
});
</script>

<style lang="scss" scoped>
$color-border: #e1e9f3;
$animation-delay: 0.2s;
$animation-duration: 0.5s;
$animation-duration-extended: 1s;
$animation-timing-badge-success: cubic-bezier(0, 1.25, 0.5, 1.25);
$amount-stages: 6;

.status {
  display: grid;
  grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
  column-gap: 5px;
  border-bottom: 8px solid $color-border;
  padding-bottom: 25px;

  @include fit(800) {
    grid-template-columns: 1fr 50px 1fr 50px 1fr 50px 1fr;
  }

  &-stage {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.is-success {
      .status-icon {
        svg:first-child {
          animation: icon-complete $animation-duration ease forwards;
        }
        &-badge {
          animation: badge-success $animation-duration
            $animation-timing-badge-success forwards;
        }
      }
      .status-title {
        animation: icon-complete $animation-duration ease forwards;
      }
      & + .status-arrow svg {
        animation: icon-complete $animation-duration ease forwards;
      }
    }

    &.is-warning {
      .status-icon {
        svg:first-child {
          animation: icon-incomplete $animation-duration ease forwards;
        }
        &-badge {
          animation: badge-warning $animation-duration-extended ease forwards;
        }
      }
      .status-title {
        animation: icon-incomplete $animation-duration ease forwards;
      }
    }

    &.is-error {
      .status-icon {
        svg:first-child {
          animation: icon-incomplete $animation-duration ease forwards;
        }
        &-badge {
          animation: badge-error $animation-duration-extended ease forwards;
        }
      }
      .status-title {
        animation: icon-incomplete $animation-duration ease forwards;
      }
    }

    // animation-delay is for stages and arrow alike
    @for $i from 1 through ($amount-stages * 2 - 1) {
      &:nth-child(#{$i}) {
        .status-icon {
          svg:first-child {
            animation-delay: $i * $animation-delay;
          }
          &-badge {
            animation-delay: $i * $animation-delay;
          }
        }

        .status-title {
          animation-delay: $i * $animation-delay;
        }

        & + .status-arrow svg {
          animation-delay: ($i + 1) * $animation-delay;
        }
      }
    }
  }

  &-icon {
    height: 45px;
    position: relative;

    @include fit(800) {
      height: 90px;
    }

    svg {
      outline: none;

      &:first-child {
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        fill: $color-secondary-bluegray;
        opacity: 0.5;
      }
    }

    &-badge {
      position: absolute;
      right: -10px;
      bottom: -5px;
      width: 30px;
      height: 30px;
      background-color: #fff;
      fill: #fff;
      border-radius: 100%;
      overflow: visible;
      padding: 2px;
      transform: scale(0);
      transition: all 0.3s ease;

      @include fit(800) {
        width: 40px;
        height: 40px;
        right: -10px;
        bottom: 5px;
      }
    }
  }

  &-title {
    font-weight: bold;
    text-transform: uppercase;
    color: $color-secondary-bluegray;
    opacity: 0.5;
    display: none;
    text-align: center;

    @include fit(800) {
      display: block;
    }
  }

  &-date {
    color: $color-primary-gray;
    display: none;
    text-align: center;

    @include fit(800) {
      display: block;
    }
  }

  &-arrow {
    margin-top: 5px;

    @include fit(800) {
      margin-top: 20px;
    }

    svg {
      width: 100%;
      height: 35px;
      fill: $color-secondary-bluegray;
      opacity: 0.5;
      outline: none;

      @include fit(800) {
        height: 50px;
      }
    }
  }

  &-latest {
    margin-top: 10px;
    grid-column: 1 / -1;

    .status-title {
      display: block;
      color: $color-primary-blue;
      opacity: 1;
      text-align: left;

      @include fit(800) {
        display: none;
      }
    }
    .status-date {
      display: block;
      text-align: left;

      @include fit(800) {
        display: none;
      }
    }
  }
}

@keyframes icon-complete {
  to {
    color: $color-primary-blue;
    fill: $color-primary-blue;
    opacity: 1;
  }
}

@keyframes icon-incomplete {
  to {
    opacity: 1;
  }
}

@keyframes badge-success {
  to {
    transform: scale(1);
    fill: $color-status-ok;
    opacity: 1;
  }
}

// adapted swing animation from animate.css
@keyframes badge-warning {
  from {
    opacity: 0;
  }

  20% {
    transform: rotate3d(0, 0, 1, 15deg) scale(1);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
    fill: $color-status-warning;
    opacity: 1;
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
    fill: $color-status-warning;
    opacity: 1;
  }
}

// adapted headshake animation from animate.css
@keyframes badge-error {
  from {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
    fill: $color-status-error;
    opacity: 1;
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
    fill: $color-status-error;
    opacity: 1;
  }

  50% {
    transform: translateX(0);
  }

  to {
    transform: scale(1);
    fill: $color-status-error;
    opacity: 1;
  }
}
</style>
