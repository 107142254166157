






































import Vue from "vue";
import { mapState, mapMutations } from "vuex";

export default Vue.extend({
  props: {
    customTitle: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  data() {
    return {
      title: "",
      isOpen: false,
      hideContent: false,
      isOpening: false,
      contentHeight: 0,
      descriptionHeight: 0,
      lastDescriptionHeight: 0,
    };
  },
  created() {
    this.setTitle();
    if (!this.hasNews) {
      this.hideContent = true;
    }
  },
  mounted() {
    this.calculateHeight();

    if (this.isStartup)
      document.addEventListener("animationend", this.handleAnimationEnd);

    document.addEventListener("scroll", this.handleScroll);
    document.addEventListener("transitionend", this.handleTransitionEnd);
  },
  methods: {
    translateTitle(key: string) {
      return this.$t("notificationBox.title." + key) as string;
    },
    setTitle() {
      this.title = this.hasNews
        ? this.translateTitle("new")
        : this.customTitle || this.translateTitle("open");
    },
    calculateHeight() {
      this.lastDescriptionHeight = this.descriptionHeight;

      this.descriptionHeight = (this.$refs
        .description as HTMLElement).scrollHeight;
      this.contentHeight =
        (this.$refs.text as HTMLElement).offsetHeight + this.descriptionHeight;
    },
    toggleState() {
      // Make sure that the content is not hidden before changing the state
      if (this.hideContent) {
        this.hideContent = false;
      }

      // When closing, or when opening for the first time, we want to immediatly open/close the box
      if (this.isOpen || (this.hasNews && this.lastDescriptionHeight == 0)) {
        this.isOpen = !this.isOpen;
        this.$nextTick().then(() => this.calculateHeight());
      } else {
        // When opening, after the box has been previously hidden, we don't want to immediatly open the box
        this.isOpening = true;
      }

      // When there are news and the box is now open, we disable the news
      if (this.hasNews && this.isOpen) {
        this.DISABLE_NEWS;
        this.setTitle();
      }
    },
    handleFocusOut(e: FocusEvent) {
      if (!this.isOpen) return;

      const mainDiv = this.$refs.main as Node;
      const target = e.relatedTarget as Node;

      if (mainDiv.contains(target)) return;

      this.isOpen = false;

      this.$nextTick().then(() => this.calculateHeight());
    },
    isElementVisible(element: Element) {
      const rect = element.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;

      return elemTop < window.innerHeight && elemBottom >= 0;
    },
    handleScroll() {
      if (!this.isOpen) return;

      const content = this.$refs.content as Element;

      if (!this.isElementVisible(content)) this.toggleState();
    },
    handleAnimationEnd(e: AnimationEvent) {
      if (
        e.target != this.$refs.content ||
        !e.animationName.startsWith("content-fadeIn")
      )
        return;

      this.END_STARTUP;
      document.removeEventListener("animationend", this.handleAnimationEnd);
    },
    handleTransitionEnd(e: TransitionEvent) {
      if (e.target != this.$refs.content) return;

      // After the box is closed, we want to hide it
      if (
        e.propertyName == "height" &&
        !this.isOpen &&
        this.lastDescriptionHeight > 0
      ) {
        this.hideContent = true;
      } else if (e.propertyName == "transform" && this.isOpening) {
        // After the box is shown, we want to open it
        this.isOpen = true;
        this.isOpening = false;

        this.$nextTick().then(() => this.calculateHeight());
      }
    },
  },
  computed: {
    ...mapState(["isStartup", "hasNews"]),
    ...mapMutations(["END_STARTUP", "DISABLE_NEWS"]),

    styleContent(): { height: string } {
      return {
        height: this.contentHeight + "px",
      };
    },
    styleDescription(): { height: string } {
      return {
        height: this.descriptionHeight + "px",
      };
    },
  },
});
