













































































































































































import Vue from "vue";
import HeroText from "@/components/modules/HeroText.vue";

export default Vue.extend({
  components: {
    HeroText,
  },
  data() {
    return {
      selectedTopic: {},
      options: [
        {
          title: this.$t("general.appointment"),
          id: "appointment",
        },
        {
          title: this.$t("general.shipmentTracking"),
          id: "tracking",
        },
        {
          title: this.$t("faq.shipmentMontagePickup"),
          id: "delivery",
        },
        {
          title: this.$t("faq.reclamationAndRetoure"),
          id: "reclamation",
        },
        {
          title: this.$t("general.contact"),
          id: "contact",
        },
      ],
    };
  },
  created() {
    // Make the first option the default
    if (this.options.length) this.selectedTopic = this.options[0];
  },
  methods: {
    changeTopic(option: IOption) {
      this.selectedTopic = option;
    },
  },
});
