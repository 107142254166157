




















// This component calculates the arrow position by itself, in case headache-causing
// issues arise, or more flexibility is needed, an external library like popper.js might be used

import Vue from "vue";

const dialogWindowMargin = 10;
const dialogBubbleMargin = 40;
const dialogMaxWidth = 370 + dialogBubbleMargin;
const dialogCornerSize = 12;
const dialogCornerBaseOffset = 14;

export default Vue.extend({
  props: {
    title: {
      type: String,
    },
    onhover: {
      type: Boolean,
      default: false,
    },
    side: {
      type: String,
      default: "auto",
      validator(val: string) {
        return ["auto", "right", "bottom", "left", "top"].includes(val);
      },
    },
  },
  data() {
    return {
      isOpen: false,
      offsetX: 0,
      offsetY: 0,
      recommendedSide: "right",
    };
  },
  computed: {
    sideClass(): string {
      return "is" + this.autoSide[0].toUpperCase() + this.autoSide.substr(1);
    },
    autoSide(): string {
      return this.side === "auto" ? this.recommendedSide : this.side;
    },

    // styleDialog and styleCorner may be abstracted more by calculating
    // the position and size of its parent, getting rid of all fixed numbers
    styleDialog(): object {
      if (this.autoSide === "right")
        return {
          top: -(this.offsetY + dialogWindowMargin) + "px",
          left: dialogBubbleMargin + "px",
          transformOrigin: `left ${dialogCornerBaseOffset + this.offsetY}px`,
        };
      else if (this.autoSide === "bottom")
        return {
          top: dialogBubbleMargin + "px",
          left: -(this.offsetX + dialogWindowMargin) + "px",
          transformOrigin: `${dialogCornerBaseOffset + this.offsetX}px top`,
        };
      else if (this.autoSide === "top")
        return {
          bottom: dialogBubbleMargin + "px",
          left: -(this.offsetX + dialogWindowMargin) + "px",
          transformOrigin: `${dialogCornerBaseOffset + this.offsetX}px bottom`,
        };
      else if (this.autoSide === "left")
        return {
          top: -(this.offsetY + dialogWindowMargin) + "px",
          right: dialogBubbleMargin + "px",
          transformOrigin: `right ${dialogCornerBaseOffset + this.offsetY}px`,
        };
      else
        console.error(
          "Info Dialog with unexpected side in styleDialog:",
          this.autoSide
        );
      return {};
    },
    styleCorner(): object {
      if (this.autoSide === "right")
        return {
          left: "-7px",
          top: `${dialogCornerBaseOffset + this.offsetY}px`,
          transform: "rotateZ(45deg)",
        };
      else if (this.autoSide === "bottom")
        return {
          top: "-7px",
          left: `${dialogCornerBaseOffset + this.offsetX}px`,
          transform: "rotateZ(135deg)",
        };
      else if (this.autoSide === "top")
        return {
          bottom: "-7px",
          left: `${dialogCornerBaseOffset + this.offsetX}px`,
          transform: "rotateZ(315deg)",
        };
      else if (this.autoSide === "left")
        return {
          right: "-7px",
          top: `${dialogCornerBaseOffset + this.offsetY}px`,
          transform: "rotateZ(225deg)",
        };
      else
        console.error(
          "Info Dialog with unexpected side in styleCorner:",
          this.autoSide
        );
      return {};
    },
  },
  methods: {
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
        window.addEventListener("click", this.close, { once: true });
      }
    },
    close() {
      this.offsetX = 0;
      this.offsetY = 0;
      this.isOpen = false;
    },
    open() {
      this.recommendedSide = this.calcRecommendedSide();
      this.isOpen = true;
    },
    calcRecommendedSide(): string {
      const windowWidth = window.screen
        ? window.screen.width
        : window.innerWidth;
      const windowHeight = window.screen
        ? window.screen.height
        : window.innerHeight;
      const clientRect = this.$el.getBoundingClientRect();

      const clientRectSpace = {
        right: windowWidth - clientRect.right,
        top: clientRect.top,
        bottom: windowHeight - clientRect.bottom,
      };

      if (clientRectSpace.right > dialogMaxWidth) return "right";
      else if (clientRectSpace.bottom > clientRectSpace.top) return "bottom";
      else return "top";
    },
    enter(el: HTMLElement) {
      const windowWidth = window.screen
        ? window.screen.width
        : window.innerWidth;
      const windowHeight = window.screen
        ? window.screen.height
        : window.innerHeight;
      const clientRect = this.$el.getBoundingClientRect();

      const dialogWidth = el.offsetWidth;
      const dialogHeight = el.offsetHeight;
      const dialogLeft = clientRect.left + 27;
      const dialogTop = clientRect.top;
      const dialogRight = dialogLeft + dialogWidth;
      const dialogBottom = dialogTop + dialogHeight;

      const offsetX =
        windowWidth < dialogRight
          ? Math.min(
              dialogRight - windowWidth,
              dialogWidth - 2 * dialogCornerBaseOffset - dialogCornerSize
            )
          : 0;
      const offsetY =
        windowHeight < dialogBottom
          ? Math.min(
              dialogBottom - windowHeight,
              dialogHeight - 2 * dialogCornerBaseOffset - dialogCornerSize
            )
          : 0;
      this.offsetX = offsetX;
      this.offsetY = offsetY;
      },
  },
});
