import store from '@/store/index'; // path to your Vuex store


import '@/extensions/DateExtensions.ts';
import '@/extensions/StringExtensions.ts';

export default class PlanedDeliveryHelper {
    public planedDeliveryTo = "";
    public planedDeliveryFrom = "";

    private order = store.getters.getOrder;

    /**
     * Construct a new plannedDeliveryHelper
     * 
     */
    constructor() {

    }

    /**
     * Calculate the planed Delivery Time String
     */
    public planedDeliveryTimeString() {

        const rahmenTour = parseInt(this.order.frameTour);
        let isRahmenTour = false;
        if (parseInt(this.order.frameTour)) {
            if (rahmenTour >= 200 && rahmenTour <= 299) {
                isRahmenTour = true;
            }
        }

        if (!(this.order.notifiedAppointment != null && this.order.notifiedAppointment != "")) {
            return false;
        }

        const notifiedAppointmentDate = new Date(this.order.notifiedAppointment);
        const nowDate = new Date(Date.now());

        if (this.order.fk_TourPlanningState == 2 && (notifiedAppointmentDate.getDate() == nowDate.getDate()
            || notifiedAppointmentDate.getDate() == nowDate.addDays(1).getDate())
            && !isRahmenTour) {
            this.planedDeliveryFrom = this.GetTwoHourTimeSlot();
            this.planedDeliveryTo = this.GetTwoHourTimeSlot(false);
        }
        else {
            const orderDateTimeStart = this.order.orderDateIntervalStart.toDateTime();
            const orderDateTimeEnd = this.order.orderDateIntervalEnd.toDateTime();

            const orderDateTimeStartHours = orderDateTimeStart.getHours();
            const orderDateTimeEndHours = orderDateTimeEnd.getHours();

            let from = orderDateTimeStart;
            let to = orderDateTimeEnd;

            if (orderDateTimeStartHours == 7 && orderDateTimeEndHours == 13) {
                from = new Date("1970-01-01T07:00:00");
                to = new Date("1970-01-01T14:00:00");
            } else if (orderDateTimeStartHours == 12 && orderDateTimeEndHours == 18) {
                from = new Date("1970-01-01T11:00:00");
                to = new Date("1970-01-01T18:00:00");
            } else if (orderDateTimeStartHours == 17 && orderDateTimeEndHours == 21) {
                from = new Date("1970-01-01T16:00:00");
                to = new Date("1970-01-01T21:00:00");
            } else if (orderDateTimeStartHours == 7 && orderDateTimeEndHours == 18) {
                from = new Date("1970-01-01T07:00:00");
                to = new Date("1970-01-01T18:00:00");
            }

            this.planedDeliveryFrom = from.toLocaleTime();
            this.planedDeliveryTo = to.toLocaleTime();
        }

        return true;
    }

    /**
     * Calculate the Two Hour Time Slot
     * @param start Start or End Time 
    */
    public GetTwoHourTimeSlot(start = true) {

        const defaultFromTime = new Date("1970-01-01T07:00:00");
        const defaultToTime = new Date("1970-01-01T19:00:00");

        const orderDateStart = this.order.orderDateIntervalStart;
        const orderDateEnd = this.order.orderDateIntervalEnd;

        const von = orderDateStart ? orderDateStart.toDateTime() : defaultFromTime;
        const bis = orderDateEnd ? orderDateEnd.toDateTime() : defaultToTime;

        const time = new Date(this.order.tourArrivalPlanned);
        const timeMinutes = time.getMinutes();


        const beginn = time.addHours(-1).addMinutes(timeMinutes % 10 < 5 ? (-1 * timeMinutes) % 5 : 10 - timeMinutes % 10);
        const ende = beginn.addHours(2);

        const temp = beginn.getDateWithZeroTime();
        const minVon = temp.addTime(von);
        const maxBis = temp.addTime(bis);

        let result = null;

        if (beginn < minVon) {
            result = start ? minVon : minVon.addHours(2);
        } else if (ende > maxBis) {
            result = start ? maxBis.addHours(-2) : maxBis;
        } else {
            result = start ? beginn : ende;
        }

        return result.toLocaleTime();
    }

}
