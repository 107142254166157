import { Store } from "vuex";
import { IState } from "@/store/types/IState";
import i18n from "@/i18n";

export class TrackingMapVisibilityService {
  private store: Store<IState>;

  constructor(store: Store<IState>) {
    this.store = store;
  }

  showTrackingMap(): boolean {
    if (this.store.state.order.currentStatus === "OnWayToCustomer") {
      return true;
    } else {
      return false;
    }
  }

  async updateDataForTrackingMap() {
    if (this.showTrackingMap()) {
      await this.store.dispatch("getDeliveryTruckMapData");

      this.store.commit("SET_ORDER_STATE_DESCRIPTION", "Auftrag unterwegs");

      this.store.commit(
        "SET_NOTIFICATION_TEXT",
        i18n.t("notificationBox.description.deliveryEnRoute") as string
      );
    }
  }
}
