




























import Vue from "vue";

export default Vue.extend({
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    message: {
      type: String,
    },
  },
  data() {
    return {
      isFocused: false,
      inputId: "",
      inputName: "",
      checked: false,
    };
  },
  created() {
    this.inputId =
      this.id ||
      "id" + window.crypto.getRandomValues(new Uint32Array(1)).toString();
    this.inputName = this.name || this.inputId;
  },
  methods: {
    change(event: { target: HTMLInputElement }) {
      this.$emit("change", event.target.checked);
    },
  },
});
