import axios from "axios";
import AppConfig, { ApplicationConfiguration } from "@/assets/config";
import {apiService, legacyApiService} from "@/services/ApiService";

export default class EnvironmentService {
  public static applicationConfiguration: ApplicationConfiguration;
  public async loadCustomConfiguration() {
      const res = await axios.get<ApplicationConfiguration>("/assets/environment.json");
      EnvironmentService.applicationConfiguration = {...EnvironmentService.applicationConfiguration, ...res.data};
      apiService.updateBaseUrl(EnvironmentService.applicationConfiguration.bookingServiceBaseUrl);
      legacyApiService.updateBaseUrl(EnvironmentService.applicationConfiguration.baseUrl);
  }
}

EnvironmentService.applicationConfiguration = AppConfig;
