


























import Vue from "vue";

export default Vue.extend({
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array as () => Array<string>,
      default: () => [],
    },
    val: {
      type: Object,
    },
    message: {
      type: String,
    },
  },
  data() {
    return {
      isFocused: false,
      checkedProxy: false,
    };
  },
  computed: {
    checked: {
      get(): string[] {
        return this.value;
      },
      set(val: boolean) {
        this.checkedProxy = val;
      },
    },
  },
  methods: {
    change: function () {
      this.$emit("input", this.checkedProxy);
    },
  },
});
