













import Vue from "vue";

export default Vue.extend({props: {footerContactFormIsVisible: {default: false}} });
