
















import Vue from "vue";

export default Vue.extend({
  props: {
    title: String,
    type: {
      type: String,
      default: "info",
      validator(val: string) {
        return ["info", "success", "warning", "error"].includes(val);
      },
    },
  },
  computed: {
    typeClass() {
      return "is" + this.type[0].toUpperCase() + this.type.substr(1);
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
});
