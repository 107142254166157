












import Vue from "vue";
import Logger from "./services/LoggerService";
import TheHeader from "@/components/sections/TheHeader.vue";
import TheFooterBar from "@/components/sections/TheFooterBar.vue";
import TheFooterContact from "@/components/sections/TheFooterContact.vue";
import { mapState, mapGetters } from "vuex";

export default Vue.extend({
  components: {
    TheHeader,
    TheFooterBar,
    TheFooterContact,
  },

  data() {
    return {
      logger: new Logger("App"),
    };
  },

  mounted() {
    this.logger.logInformation("Mounting...");
  },

  computed: {
    ...mapState(["isLoggedIn", "blockScroll"]),
    ...mapGetters(["isIkeaClient"]),
  },
});
