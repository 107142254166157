
export default class ViewportHelper {
    /**
     *
     * This method checks if an element is in the viewport, considering the respective padding and an optional tolerance
     * @param element
     * @param tolerance
     */
    public isInViewport(element: HTMLElement, tolerance = 0) {
      // Check if the element is in the viewport
      const computedStyle = window.getComputedStyle(element);
      const elementPaddingTop = parseInt(computedStyle.getPropertyValue("padding-top")) || 0;
      const elementPaddingBottom = parseInt(computedStyle.getPropertyValue("padding-bottom")) || 0;
      const topTolerance = element.clientHeight - elementPaddingBottom - tolerance;
      const bottomTolerance = element.clientHeight - elementPaddingTop - tolerance;
      const rect = element.getBoundingClientRect();
      const isInViewport =
        rect.top >= this.getHeaderHeight() - topTolerance &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + bottomTolerance;

      return isInViewport;
    }

    /**
     *
     * This method gets the height of the global header
     * @returns The height (0 if no header found)
     */
    public getHeaderHeight(){
        const header = document.getElementsByTagName("header")[0];
        return !header ? 0 : header.clientHeight;
    }

    /**
     *
     * This method checks if the global footer is in the viewport and returns the visible height from the footer
     * @returns The visible height (0 if none visible)
     */
    public getVisibleFooterHeight() {
        const footer = document.getElementsByTagName("footer")[0];
        if (!footer) return 0;

        const windowHeight = window.innerHeight;
        const scrollPosition = document.documentElement.scrollTop;
        const footerTop = footer.offsetTop - windowHeight;
        const footerVisible = scrollPosition >= footerTop;

        if (!footerVisible) return 0;

        const visibleFooterHeight = scrollPosition - footerTop;
        return visibleFooterHeight;
    }
}