

















import Vue from "vue";

export default Vue.extend({
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    ctaTitle: {
      type: String,
    },
    ctaCallback: {
      type: Function,
    },
  },
});
