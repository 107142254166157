






























import Vue from "vue";

export default Vue.extend({
  props: {
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    message: {
      type: String,
    },
    autoexpand: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
    },
    maxlength: {
      type: Number,
    },
  },
  data() {
    return {
      isFocused: false,
      inputId: "",
      inputName: "",
    };
  },
  created() {
    this.inputId =
      this.id ||
      "id" + window.crypto.getRandomValues(new Uint32Array(1)).toString();
    this.inputName = this.name || this.inputId;
  },
  computed: {
    isLabelAtop(): boolean {
      return this.isFocused || !!this.value;
    },
    validity(): object {
      const inputElement = this.$refs.textarea as HTMLFormElement;
      return inputElement.validity;
    },
  },
  mounted() {
    // fit on mount, in case someone wrote a long fairy tale as prefilled value
    this.fitHeightToContent();
  },
  methods: {
    inputEvent(event: { target: HTMLInputElement }) {
      this.$emit("input", event.target.value);

      if (this.autoexpand) {
        this.fitHeightToContent();
      }
    },
    fitHeightToContent() {
      // is method, as computed props don't recalculate for style changes

      const textarea = this.$refs.textarea as HTMLElement;
      textarea.style.height = "inherit";
      const computed = window.getComputedStyle(textarea);
      const isBorderBox =
        computed.getPropertyValue("box-sizing") === "border-box";

      let height =
        parseInt(computed.getPropertyValue("border-top-width"), 10) +
        textarea.scrollHeight +
        parseInt(computed.getPropertyValue("border-bottom-width"), 10);

      // add padding, depending on box-sizing
      if (!isBorderBox) {
        height += parseInt(computed.getPropertyValue("padding-top"), 10);
        height += parseInt(computed.getPropertyValue("padding-bottom"), 10);
      }

      textarea.style.height = height + "px";
    },
  },
});
