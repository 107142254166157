









import Vue from "vue";
import HeroText from "@/components/modules/HeroText.vue";
import { mapState, mapMutations } from "vuex";
import i18n from '@/i18n/index';

export default Vue.extend({
  components: {
    HeroText,
  },
  data() {
    return {
      errorMessage: ''
    };
  },
  mounted() {
    this.errorMessage = this.customErrorMessage || i18n.t('error.pageError');
    this.SET_CUSTOM_ERROR_MESSAGE();
  },
  computed: {
    ...mapState(["customErrorMessage"]),
  },
  methods: {
    ...mapMutations(["SET_CUSTOM_ERROR_MESSAGE"])
  }
});
