import router from "@/router";
import { getRouteName, getDirectRouteName } from "@/routeConfig";

const navigationMixin = {
    methods: {
        $navigateTo: function(routeName: string): void {
            const name = getRouteName(routeName);
            router.push({ name });
        },
        //directs to the routename without any validations
        $navigateDirectTo: function(routeName: string): void {
            const name = getDirectRouteName(routeName);
            router.push({ name });
        }
    }
};

export default navigationMixin;