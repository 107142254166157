import {featureFlags} from "@/store/feature-flag.store";
import {AxiosResponse} from "axios";
import {apiService, legacyApiService} from "./ApiService";
import {IAppointmentsRequest, IAppointmentTo, IIcsFileData, ISetAppointmentRequest} from "@/store/types/Appointment";
import {getCurrencyCode} from "@/i18n";
import {Appointment} from "@/components/modules/types/Calendar";
import CalendarHelper from "@/helpers/CalendarHelper";
import {CreateAppointmentRequest} from "@/services/types/CreateAppointmentRequest";
import * as ics from 'ics';

export class AppointmentService {

    private calendarHelper = new CalendarHelper();

    async getAppointments(request: IAppointmentsRequest) {
        let response: AxiosResponse;
        //TODO ENDCP-58 fix currency to general solution in avisierungsservice
        const currency = getCurrencyCode(request.countryCode);

        if (featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_APPOINTMENT) {
            response = await apiService.get(
                `Scheduling/${request.internalOrderId}`,
                {},
                "get_appointments"
            );
        } else {
            response = await legacyApiService.post("api/appointment", request, "get_appointments")
        }

        return response.data.map((appointment: IAppointmentTo) => {
            return {
                id: appointment.id,
                start: appointment.fromTime,
                end: appointment.toTime,
                timeslot: '',
                date: this.calendarHelper.getFormattedDate(appointment.fromTime),
                price: appointment.price,
                isRecommendation: appointment.isRecommended,
                currency: currency,
                priceId: appointment.priceId
            } as Appointment;
        }) as Appointment[];
    }

    async setAppointments(confirmedAppointment: ISetAppointmentRequest) {
      if (featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_APPOINTMENT) {
        const payload: CreateAppointmentRequest = {
          id: confirmedAppointment.id,
          internalOrderIds: [confirmedAppointment.internalOrderId],         
          from: `${confirmedAppointment.fromTime}:00`,
          to: `${confirmedAppointment.toTime}:00`,
          scheduledDate: confirmedAppointment.appointment,
          price: Number(confirmedAppointment.costs),
          priceId: confirmedAppointment.priceId,
        };

        return apiService.post('SelfScheduling/BookAppointment', payload, "confirm_appointment")
          .then((response: AxiosResponse) => {
            return response.data;
          });
      }
      return legacyApiService.post('api/appointment/setAppointment', confirmedAppointment, "confirm_appointment")
        .then((response: AxiosResponse) => {
          return response.data;
        });
    }

    async getAppointmentIcsFile(icsFileData: IIcsFileData){
      if (featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_APPOINTMENT) {
          const icsFile = ics.createEvent({
              start: icsFileData.fromTime,
              end: icsFileData.toTime,
              title: icsFileData.emailSubject,
              location: icsFileData.address,
              htmlContent: `<!DOCTYPE HTML PUBLIC '-//W3C//DTD HTML 3.2//EN'><BODY>${icsFileData.emailBody}</BODY><HTML>`
          });
  
          return {data: icsFile.value};
  
      }
  
      return legacyApiService.post('api/appointment/getIcsFile', icsFileData, "get_ics_file");
  }
}

export const appointmentService = new AppointmentService();
