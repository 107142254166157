import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import i18n from '@/i18n/index'
import store from '@/store/index';
import {makeServer} from "@/services/server";
import {extendMoment} from 'moment-range';
import {VueReCaptcha} from 'vue-recaptcha-v3';
import "@/filters";
import navigationMixin from './mixins/NavigationMixin';
import EnvironmentService from './services/EnvironmentService';
import axios from "axios";
import {loadFeatureFlags} from "@/services/FeatureFlagService";
import {featureFlags} from "@/store/feature-flag.store";


async function main() {

    const environmentService = new EnvironmentService();
    if (process.env.NODE_ENV !== 'development') {
        await environmentService.loadCustomConfiguration();
    }

    try {
        await loadFeatureFlags();

        if(store.state.isLoggedIn) {
            featureFlags.setClientKey(store.state.service.order.clientKey!)
        }
    } catch {
        //TODO When adding app insights this could be logged as error
    }


    // moment-range
    const moment = extendMoment(require('moment'))

    // moment locales
    require('moment/locale/de')
    const momentOptions = {
        week: {
            dow: 1, // First day of week is Monday
            doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
        }
    }
    moment.updateLocale('en', momentOptions);
    moment.updateLocale('de', momentOptions);

    // vue moment
    Vue.use(require('vue-moment'), {moment})
    Vue.mixin(navigationMixin);
    // Use development API server
    if (process.env.NODE_ENV === 'development' && EnvironmentService.applicationConfiguration.isMocking)
        makeServer({environment: 'development'});


    // Global component registrations of base elements
    const requireComponent = require.context('./components/elements', true, /Rh[A-Z]\w+\.(vue|js)$/);
    requireComponent.keys().forEach(function (fileName) {
        let baseComponentConfig = requireComponent(fileName);
        baseComponentConfig = baseComponentConfig.default || baseComponentConfig;
        const baseComponentName = fileName
            .replace(/^.+\//, '')
            .replace(/\.\w+$/, '');
        Vue.component(baseComponentName, baseComponentConfig);
    });

    Vue.config.productionTip = false;

    Vue.use(VueReCaptcha, {
        siteKey: EnvironmentService.applicationConfiguration.recaptchaKey, loaderOptions: {
            //autoHideBadge: true
        }
    });

    const timeout = isNaN(EnvironmentService.applicationConfiguration.inactivityTimeout) ? 5 :
        Number(EnvironmentService.applicationConfiguration.inactivityTimeout);
    const timeoutInMilliseconds = timeout * 60 * 1000;

    axios.interceptors.request.use(config => {
        if (store.state.isLoggedIn) {
            const lastRequestDate = store.state.lastRequestDate;

            if (lastRequestDate &&
                (new Date().getTime() - new Date(lastRequestDate).getTime() > timeoutInMilliseconds)) {
                store.commit('LOGOUT');
                window.location.href = '/';
                return Promise.reject('Session expired');
            } else {
                store.dispatch('setNewRequest');
            }
        }
        return config;
    });

    new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App),
    }).$mount('#app');

}


(async () => {
    await main();
})();
