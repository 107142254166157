import { AxiosResponse } from "axios";
import { apiService, legacyApiService } from "./ApiService";
import { featureFlags } from "@/store/feature-flag.store";
import { IGeoAddress } from "@/store/types/Geo";
import { ILatLng } from "@/store/types/Order";
import { DeliveryTruckMapData } from "./types/TrackingMapData";
import { TrackingStatus } from "./types/TrackingStatus";

export class TrackingService {
  public async GetEstimatedTimeOfArrival(internalOrderId: string) {
    const response = await apiService.get(
      `SelfScheduling/eta/${internalOrderId}`,
      {},
      "get_eta"
    );

    return response.data;
  }

  public async GetCoordinates(internalOrderId: string, address: IGeoAddress) {
    let response: AxiosResponse<ILatLng>;
    if (featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_TRACKING) {
      response = await apiService.get(
        `SelfScheduling/geo-coordinates/${internalOrderId}`,
        {},
        "get_coordinates"
      );
    } else {
      response = await legacyApiService.post(
        "api/geo/getGeoCodeViaAddress",
        address,
        "get_coordinates"
      );
    }

    return response.data;
  }

  public async getDeliveryTruckMapData(internalOrderId: string) {
    return apiService.get<DeliveryTruckMapData>(
      `SelfScheduling/TourOrderDetail/${internalOrderId}`,
      {},
      "get_delivery_truck_map_data"
    );
  }

  public async GetTrackingStatus(internalOrderId: string)
  {
    return apiService.get<TrackingStatus[]>(
      `SelfScheduling/order-tracking-status/${internalOrderId}`,
      {},
      "get_order_tracking_status"
    );
  }
}

export const trackingService = new TrackingService();
