











































import Vue from "vue";
import HeroText from "@/components/modules/HeroText.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { getRouteName } from "@/routeConfig";

export default Vue.extend({
  components: {
    HeroText,
  },
  computed: {
    ...mapGetters(["getCanMakeAppointment"]),
    ...mapState(["appointmentError"]),
  },
  methods: {
    getContactLink() {
      return { name: getRouteName("contact") };
    },
    getAppointments() {
      if(this.getCanMakeAppointment) {
        this.requestCustomerAppointments()
          .finally(() => {
            this.$navigateDirectTo("deliveryRestriction");
          });
      } else {
        this.$navigateTo("contact");
      }
    },
    ...mapActions(["requestCustomerAppointments"]),
  },
});
