export { }

import "./DateExtensions.ts";

declare global {
    interface String {
        /**
         * Converts a date-string value to a new date-string value appropriate to the host environment's current locale.
         * @param withTime A boolean value indicating if the time should be part of the result.
         * @param withSeconds A boolean value indicating if seconds should be part of the result.
         */
        toLocaleDate(withTime?: boolean, withSeconds?: boolean): string;
        /**
         * Converts a date-string value to a new date-string value appropriate to the host environment's current locale (including the weekday).
         * @param short A boolean value indicating if the weekday should be short.
         * @param withTime A boolean value indicating if the time should be part of the result.
         * @param withSeconds A boolean value indicating if seconds should be part of the result.
         */
        toLocaleDateWithWeekday(short?: boolean, withTime?: boolean, withSeconds?: boolean): string;
        /** Returns a date representation of a time-string (with the date being 1970-01-01). */
        toDateTime(): Date;
    }
}

String.prototype.toLocaleDate = function (withTime, withSeconds) {
    const date = new Date(this.valueOf());
    return date.toLocaleDate(withTime, withSeconds);
}

String.prototype.toLocaleDateWithWeekday = function (short, withTime, withSeconds) {
    const date = new Date(this.valueOf());
    return date.toLocaleDateWithWeekday(short, withTime, withSeconds);
}

String.prototype.toDateTime = function () {
    const [hours, minutes, seconds] = this.valueOf().split(":");

    const date = new Date("1970-01-01");
    date.setHours(+hours);
    date.setMinutes(+minutes);
    date.setSeconds(seconds ? +seconds : 0);

    return date;
}
